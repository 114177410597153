import { ACTION_HUB_SUBMITTED_TASK_LABELS } from 'actionHub/redux/actionTypes'
import * as ActionTypes from 'actions/job'
import { sortByFunction } from 'utils/arrays'
import { decorateJob } from 'utils/business/jobs'

export const jobInitialState = {
  _fetchingActionsError: null,
  _fetchingJobDetailsId: null,
  _fetchedJobDetailsId: null,
  _isCompletedJob: false,
  _isCreateJobError: false,
  _isCreatingActions: false,
  _isDownloadingJobData: false,
  _isFetchedActions: false,
  _isFetchedExternalRoles: false,
  _isFetchedReports: false,
  _isFetchedSampleDataJson: false,
  _isFetchingActions: false,
  _isFetchingJob: true,
  _isFetchingJobMembers: false,
  _isFetchingReports: false,
  _isFetchingSampleDataJson: false,
  _isFetchReportError: false,
  _isLaunchingWorkbench: false,
  _isLoadingDataRequirements: false,
  _isModifyingUserRole: false,
  _isReportRepublishRequested: false,
  _isRequestingExecute: false,
  _isSampleDataError: false,
  _isSendingJob: false,
  _isSettingCategoryParameters: false,
  _isSettingParameters: false,
  _isSettingSolutionParameters: false,
  _isTestsChanged: false,
  _isUploading: false,
  _isUploadingFiles: false,
  _isValidatingData: false,
  actions: null,
  analyses: [],
  assignableRoles: {
    externalRoles: [],
    internalRoles: [],
  },
  availableTargetSchemas: ["I don't know"],
  benchmarkingEnabled: 'Yes',
  clientName: '',
  dataPreparationRequest: null,
  dpFrom: '',
  dpTo: '',
  dpYE: '',
  downloads: [],
  engagement: {
    active: true,
    code: '',
    name: '',
  },
  engagementCode: '',
  engagementManager: '',
  engagementPartner: '',
  executionLimit: -1,
  fileGroupFilesUploadStatus: [],
  fileGroups: [],
  files: [],
  hasActions: false,
  hasCurrentActions: false,
  hasExecutionLimit: false,
  hideStepper: false,
  ignoreErrors: false,
  isCalculated: false,
  isClientJob: false,
  isCustom: false,
  isDataReady: null,
  isSpinning: false,
  jobId: '',
  jobName: '',
  member: null,
  members: [],
  name: '',
  noOfRuns: 0,
  packageName: '',
  packageParameters: [],
  packageValue: '',
  recipeName: null,
  recipes: [],
  reports: null,
  sampleDataJson: null,
  selectedSampleTable: 0,
  selectedTestId: null,
  setupErrors: [],
  step: 1,
  supportingFileStatus: [],
  supportingFiles: [],
  tables: [],
  validations: {},
}

const resetStateIfNeeded = (actionJobId, state) => ((actionJobId && state.jobId !== '' && actionJobId !== state.jobId)
  ? {
    ...jobInitialState,
    jobId: actionJobId,
    _fetchingJobDetailsId: state._fetchingJobDetailsId,
    _isFetchingReports: state._isFetchingReports,
  }
  : state)

export default function job(state = jobInitialState, action) {
  switch (action.type) {
    case ActionTypes.JOB_RESET:
      return jobInitialState

    case ActionTypes.JOB_UPDATE_SELECTED_TEST:
      return {
        ...state,
        analyses: state.analyses.filter(
          test => action.selected.indexOf(test.id) > -1,
        ),
      }

    case ActionTypes.JOB_FETCHING_JOB:
      return {
        ...state,
        _isFetchingJob: action.fetching,
        _isFetchReportError: false,
        ignoreErrors: false,
      }

    case ActionTypes.JOB_FETCHING_JOB_DETAILS:
      return {
        ...resetStateIfNeeded(action.jobId, state),
        _isFetchingJob: true,
        _isFetchReportError: false,
        _fetchingJobDetailsId: action.jobId,
        ignoreErrors: false,
      }
    case ActionTypes.JOB_FETCHED_JOB_DETAILS:
      return {
        ...resetStateIfNeeded(action.jobId, state),
        ...decorateJob(action.job),
        _fetchingJobDetailsId: null,
        _fetchedJobDetailsId: action.job.jobId,
      }

    case ActionTypes.JOB_SAVING:
      return {
        ...state,
        _isSendingJob: true,
      }

    case ActionTypes.JOB_UPLOADING:
      return {
        ...state,
        _isUploading: action.uploading,
      }

    case ActionTypes.DATA_PREP_REQUIRED:
      return {
        ...state,
        isDataReady: action.isDataReady,
      }

    case ActionTypes.DATA_PREP_REQUEST_UPDATED:
      return {
        ...state,
        dataPreparationRequest: action.dataPreparationRequest,
      }

    case ActionTypes.FILE_GROUPS_UPDATED:
      return {
        ...state,
        fileGroups: action.fileGroups,
      }

    case ActionTypes.SUPPORTING_FILES_GROUP:
      return {
        ...state,
        supportingFiles: action.files,
      }

    case ActionTypes.SUPPORTING_FILES_GROUP_STATUS:
      return {
        ...state,
        supportingFileStatus: action.fileStatus,
      }

    case ActionTypes.JOB_SAVED:
      return {
        ...state,
        _isSendingJob: false,
        _isCompletedJob: true,
      }

    case ActionTypes.JOB_SOLUTION_PARAMETERS_UPDATED:
      return {
        ...state,
        _isSettingSolutionParameters: false,
      }

    case ActionTypes.JOB_RESET_CTA:
      return {
        ...state,
        _isSendingJob: false,
        _isCompletedJob: false,
      }

    case ActionTypes.JOB_FILE_UPDATE:
      return {
        ...state,
        selectedTests: state.selectedTests.map((test, idx) => {
          if (action.index === idx) {
            return Object.assign({}, test, {
              file: action.file,
            })
          }
          return Object.assign({}, test)
        }),
      }

    case ActionTypes.JOB_UPDATE_JOB:
      return {
        ...resetStateIfNeeded(action.job.jobId, state),
        ...action.job,
        dataDownloadKey: action.job.dataDownloadPassword,
      }

    case ActionTypes.JOB_ADD_JOB_MEMBERS:
      return {
        ...state,
        members: [
          ...state.members,
          ...action.members,
        ],
      }

    case ActionTypes.JOB_UPDATED_JOB_MEMBERS:
      return {
        ...state,
        ...action.processedMembers,
      }

    case ActionTypes.JOB_FETCHING_JOB_MEMBERS:
      return {
        ...state,
        _isFetchingJobMembers: true,
      }

    case ActionTypes.JOB_FETCHED_JOB_MEMBERS:
      return {
        ...state,
        ...action.processedMembers,
        _isFetchingJobMembers: false,
      }

    case ActionTypes.JOB_REMOVE_JOB_MEMBER:
      return {
        ...state,
        members: action.jobId === state.jobId
          ? state.members.filter(x => x.id !== action.userId)
          : state.members,
      }

    case ActionTypes.JOB_FETCHING_REPORTS:
      return {
        ...resetStateIfNeeded(action.jobId, state),
        _isFetchingReports: true,
        _isFetchReportError: false,
      }

    case ActionTypes.FETCHED_CONNECTED_JOBS:
      return {
        ...state,
        connectedJobsActions: action.connectedJobsActions,
      }

    case ActionTypes.JOB_FETCHED_TARGET_SCHEMAS:
      return {
        ...state,
        availableTargetSchemas: action.availableTargetSchemas,
      }

    case ActionTypes.JOB_FETCHED_REPORTS:
      return {
        ...resetStateIfNeeded(action.jobId, state),
        _isFetchingReports: false,
        _isFetchReportError: false,
        _isFetchedReports: true,
        reports: action.reports,
      }

    case ActionTypes.JOB_UPDATE_JOB_REPORT:
      return {
        ...state,
        reports: state.reports.map(r => (r.analysisId === action.analysisId
          ? action.report
          : r)),
      }

    case ActionTypes.JOB_ERROR_FETCHING_REPORTS:
      return {
        ...state,
        _isFetchingReports: false,
        _isFetchReportError: true,
        reports: [],
      }

    case ActionTypes.JOB_REPUBLISHED_REPORTS:
      return {
        ...state,
        _isReportRepublishRequested: true,
        jobStatusDesc: 'Processing Report',
      }

    case ActionTypes.JOB_DATA_DOWNLOAD_START:
      return {
        ...state,
        _isDownloadingJobData: true,
      }

    case ActionTypes.JOB_DATA_DOWNLOAD_COMPLETE:
      return {
        ...state,
        _isDownloadingJobData: false,
      }

    case ActionTypes.JOB_SETTING_PARAMETERS:
      return {
        ...state,
        _isSettingParameters: true,
      }

    case ActionTypes.JOB_SETTING_CATEGORY_PARAMETERS:
      return {
        ...state,
        _isSettingCategoryParameters: true,
      }

    case ActionTypes.JOB_SETTING_SOLUTION_PARAMETERS:
      return {
        ...state,
        _isSettingSolutionParameters: true,
      }

    case ActionTypes.JOB_FINISHED_SETTING_PARAMETERS:
      return {
        ...state,
        _isSettingParameters: false,
      }

    case ActionTypes.JOB_FINISHED_SETTING_CATEGORY_PARAMETERS:
      return {
        ...state,
        _isSettingCategoryParameters: false,
      }

    case ActionTypes.JOB_IGNORE_ERRORS:
      return {
        ...state,
        ignoreErrors: action.ignore,
      }

    case ActionTypes.JOB_FETCHING_SAMPLE_DATA_JSON:
      return {
        ...state,
        _isFetchingSampleDataJson: true,
      }

    case ActionTypes.FILE_GROUPS_FILES_UPLOAD_STATUS:
      return {
        ...state,
        fileGroupFilesUploadStatus: action.fileGroupFilesUploadStatus,
      }

    case ActionTypes.FILE_GROUPS_FILE_UPLOAD_STATUS: {
      const toUpdate = state.fileGroupFilesUploadStatus
        .map(fgfs => ((fgfs.file.name === action.fileGroupFileUploadStatus.file.name
          && fgfs.fileGroup.name === action.fileGroupFileUploadStatus.fileGroup.name) ? action.fileGroupFileUploadStatus : fgfs))
      return {
        ...state,
        fileGroupFilesUploadStatus: toUpdate,
      }
    }

    case ActionTypes.JOB_FETCHED_SAMPLE_DATA_JSON:
      return {
        ...state,
        sampleDataJson: action.data.isAxiosError ? null : action.data.data.slice().sort(sortByFunction(x => (x.metaData.recipeName ? x.metaData.recipeName : 'zzz'))),
        _isFetchingSampleDataJson: false,
        _isFetchedSampleDataJson: true,
        _isSampleDataError: action.data.isAxiosError,
      }

    case ActionTypes.JOB_CHANGED_SELECTED_SAMPLE_TABLE:
      return {
        ...state,
        selectedSampleTable: action.index,
      }

    case ActionTypes.JOB_UPLOADING_FILES_START:
      return {
        ...state,
        _isUploadingFiles: true,
      }

    case ActionTypes.LAUNCHING_WORKBENCH:
      return {
        ...state,
        _isLaunchingWorkbench: true,
      }

    case ActionTypes.JOB_UPLOADING_FILES_END:
      return {
        ...state,
        _isUploadingFiles: false,
      }

    case ActionTypes.LAUNCHED_WORKBENCH:
      return {
        ...state,
        _isLaunchingWorkbench: false,
      }

    case ActionTypes.JOB_VALIDATING_DATA_START:
      return {
        ...state,
        _isValidatingData: true,
      }

    case ActionTypes.START_SPINNING_VISUALISATION:
      return {
        ...state,
        isSpinning: true,
      }

    case ActionTypes.JOB_VALIDATING_DATA_END:
      return {
        ...state,
        _isValidatingData: false,
        _isSendingJob: false,
      }

    case ActionTypes.FINISH_SPINNING_VISUALISATION:
      return {
        ...state,
        isSpinning: false,
        _isSendingJob: false,
      }

    case ActionTypes.JOB_REQUEST_EXECUTE_START:
      return {
        ...state,
        _isRequestingExecute: true,
      }

    case ActionTypes.JOB_FETCHING_DATA_REQUIREMENTS:
      return {
        ...state,
        _isLoadingDataRequirements: true,
      }

    case ActionTypes.JOB_RECEIVED_DATA_REQUIREMENTS:
      return {
        ...state,
        _isLoadingDataRequirements: false,
      }

    case ActionTypes.JOB_FETCHING_ACTIONS:
      return {
        ...state,
        _isFetchingActions: true,
        _fetchingActionsError: null,
      }

    case ActionTypes.JOB_FETCHED_ACTIONS:
      return {
        ...state,
        _isFetchingActions: false,
        _isFetchedActions: true,
        _fetchingActionsError: null,
        actions: {
          ...action.actions,
          _hasFetchedAllActions: true,
          entities: action.actions.currentEntityMetas?.map(e => e.entityMeta?.entityMetaCode) ?? [],
        },
      }

    case ActionTypes.JOB_ERROR_FETCHING_ACTIONS:
      return {
        ...state,
        _isFetchingActions: false,
        _isFetchedActions: true,
        _fetchingActionsError: action.error.message,
      }

    case ActionTypes.JOB_FETCHED_CURRENT_ACTIONS:
      return {
        ...resetStateIfNeeded(action.jobId, state),
        _isFetchingJob: state._isFetchingJob,
        hasCurrentActions: action.currentActions.length > 0,
        actions: {
          ...state.actions,
          currentActions: (state.actions && state.actions.currentActions) || action.currentActions,
        },
      }

    case ActionTypes.JOB_CREATING_JOB_ACTIONS:
      return {
        ...state,
        _isCreatingActions: true,
      }

    case ActionTypes.JOB_CREATED_JOB_ACTIONS:
      return {
        ...state,
        _isCreatingActions: false,
      }

    case ActionTypes.JOB_UPDATE_JOB_DOWNLOAD:
      return {
        ...state,
        downloads: state.downloads.length > 0
          ? state.downloads.map(d => (d.name === action.downloadName
            ? {
              ...d,
              ...action.download,
            }
            : d))
          : [action.download],
      }

    case ACTION_HUB_SUBMITTED_TASK_LABELS:
      return {
        ...state,
        downloads: state.downloads
          .map(dl => (dl.type === 'action'
            ? { ...dl, status: 'Not started', statusId: 0 }
            : dl)),
      }

    case ActionTypes.JOB_MODIFYING_USER_ROLE:
      return {
        ...state,
        _isModifyingUserRole: true,
      }

    case ActionTypes.JOB_MODIFIED_USER_ROLE:
      return {
        ...state,
        _isModifyingUserRole: false,
        members: state.members.map((m) => {
          if (action.success && m.id === action.userId) {
            return {
              ...m,
              role: m.jobAssignableRoles.find(r => r.id === action.roleId),
            }
          }
          return m
        }),
      }

    case ActionTypes.JOB_FETCHED_EXTERNAL_ROLES:
      return {
        ...state,
        _isFetchedExternalRoles: true,
        assignableRoles: {
          internalRoles: state.assignableRoles.internalRoles,
          externalRoles: action.roles,
        },
      }

    default:
      return state
  }
}
