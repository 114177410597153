/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from 'components/Card'
import { Heading, Button } from '@deloitte/gel-library'
import { createJobActions, fetchActions } from 'actions/job'
import actionHubTargetImg from 'images/action-hub-target.svg'
import { userPropType } from 'utils/propTypes'
import LoadingBox from 'components/LoadingBox'
import { jobIsProcessing } from 'utils/business/workflow'
import { fetchClientActions } from 'actions/client'
import { roles } from 'utils/business/jobs'
import { useDispatch, useSelector } from 'react-redux'
import ActionGroup from 'components/ActionGroup'
import useJob from 'hooks/useJob'
import classNames from 'classnames'
import styles from './CurrentActions.scss'

const propTypes = {
  entityFilter: PropTypes.string,
  job: PropTypes.shape({
    _fetchingActionsError: PropTypes.string,
    _isCreatingActions: PropTypes.bool,
    _isFetchedActions: PropTypes.bool,
    _isFetchingActions: PropTypes.bool,
    jobId: PropTypes.string,
    lastExecution: PropTypes.object,
    member: PropTypes.object,
    members: PropTypes.arrayOf(userPropType),
  }).isRequired,
  onEditData: PropTypes.func,
  uploaders: PropTypes.shape({
    uploaders: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({
    _isFetched: PropTypes.bool,
    isExternal: PropTypes.bool,
  }).isRequired,
  validations: PropTypes.shape({
    validations: PropTypes.object,
  }).isRequired,
}

function CurrentActions({
  user: { isExternal, _isFetched }, onEditData, job, entityFilter,
}) {
  const dispatch = useDispatch()

  const {
    jobId,
    _isFetchedActions,
    _isFetchingActions,
    _isCreatingActions,
    _fetchingActionsError,
    lastExecution,
    member,
    members,
  } = job
  const isProcessing = jobIsProcessing(job)
  const currentUser = useSelector(state => state.app.user)
  const { actions, actionsList, entityMetasList } = useJob(jobId, isExternal)
  const { potentialActions } = actions || []

  const userJobRole = (member && member.role?.name)
    || (members && members.find(m => m.id === currentUser.id)?.role?.name)
    || null

  const isActionEditor = !isExternal || (userJobRole === roles.JobExternalEditor || userJobRole === roles.JobExternalActionEditor)

  useEffect(() => {
    if (jobId && _isFetched) {
      if (isExternal && !isActionEditor) {
        dispatch(fetchClientActions(jobId))
      } else {
        dispatch(fetchActions({ jobId }))
      }
    }
  }, [dispatch, jobId, isExternal, _isFetched, isActionEditor])

  const showCurrentActions = actionsList && actionsList.length > 0

  const showPotentialActions = potentialActions
    && !isProcessing
    && !showCurrentActions
    && lastExecution

  const potentialActionSum = showPotentialActions && potentialActions
    .map(x => x.totalActions)
    .reduce((a, b) => a + b, 0)

  const createActions = () => {
    dispatch(createJobActions({ jobId: job.jobId }))
  }

  const canRenderActions = _isFetchedActions && !_fetchingActionsError && actions && actions._hasFetchedAllActions

  const filteredEntityMetas = entityFilter !== ''
    ? entityMetasList.filter(e => e.entityMeta?.entityMetaCode === entityFilter)
    : entityMetasList

  return (
    <div className={styles.base}>
      {!_isFetchedActions && _isFetchingActions && <LoadingBox className={styles.loading} />}

      {_fetchingActionsError && <p>There was an error loading the actions for your job. Please try again later.</p>}

      {canRenderActions && (
        <>
          {showPotentialActions && lastExecution.isCurrentPackageVersion && potentialActionSum > 0 && (
            <Card className={styles.createBox}>
              <img alt="Action Hub" className={styles.icon} src={actionHubTargetImg} />
              <div>
                <Heading className={styles.createHeading} level={8}>Your new actions are ready! Get started by creating them.</Heading>
                <p>{`${potentialActionSum} actions available`}</p>
              </div>

              <Button
                className={styles.createButton}
                disabled={_isCreatingActions}
                onClick={createActions}
              >
                Create all
              </Button>
            </Card>
          )}

          {showPotentialActions && lastExecution.isCurrentPackageVersion && potentialActionSum === 0 && (
            <Card className={styles.noActionBox}>
              <Heading className={styles.createHeading} level={8}>You have no actions</Heading>
              <p>
                Based on your selected analyses applied to data
                <br />
                processed. Try
                {' '}
                <a onClick={onEditData}>uploading new data</a>
                .
              </p>
            </Card>
          )}

          {/* Outdated package version message */}
          {showPotentialActions && !lastExecution.isCurrentPackageVersion && (
            <Card className={styles.noActionBox}>
              <Heading className={styles.createHeading} level={8}>This solution has been updated</Heading>
              <p>
                Please
                {' '}
                <a onClick={onEditData}>run this job again</a>
                <br />
                in order to use it with Action Hub.
              </p>
            </Card>
          )}

          {filteredEntityMetas && filteredEntityMetas.length > 0 && (
            <Card noPadding className={styles.actionHeader}>
              <div className={styles.rowHeader}>
                <div className={classNames(styles.column, styles.firstColumn)}>Fund name</div>
                <div className={styles.column}>Fund Code</div>
                <div className={styles.column}>Due date</div>
                <div className={classNames(styles.column, styles.clientTeamColumn)}>Client team</div>
                <div className={styles.column}>Status</div>
                <div className={styles.endColumn} />
              </div>
            </Card>
          )}

          <div className={styles.actions}>
            {entityMetasList.length > 0 && filteredEntityMetas.length === 0 && (
              <Card className={styles.noResults}>
                <div>
                  <p>No funds found</p>
                  <p>Try adjusting your search or filter to find what you’re looking for.</p>
                </div>
              </Card>
            )}

            {filteredEntityMetas && filteredEntityMetas.map(group => (
              <div className={styles.entityMetaGroup} key={group.entityMeta.id}>
                <ActionGroup
                  actions={group.actions}
                  canEdit={isActionEditor}
                  clientTeam={group.clientTeamIds}
                  entityMeta={entityMetasList.find(em => em.entityMeta.id === group.entityMeta.id)?.entityMeta}
                  job={job}
                  key={group.entityMeta.id}
                  showHeader={false}
                />
              </div>
            ))}

            {/* Render ungrouped actions if any exist without EntityMeta */}
            {entityMetasList.length === 0 && actionsList && actionsList.length > 0 && (
              <ActionGroup
                actions={actionsList.filter(a => a.actionSet?.entityMetaId === null)}
                canEdit={isActionEditor}
                job={job}
                showHeader={false}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

CurrentActions.defaultProps = {
  entityFilter: '',
  onEditData: null,
}
CurrentActions.propTypes = propTypes

export default CurrentActions

