import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Card from 'components/Card/Card'
import { Icons } from '@deloitte/gel-library'
import { useDispatch, useSelector } from 'react-redux'
import { modalCreate, modalShow } from 'actions/modals'
import { MODAL_IMPORT_ACTIONS } from 'constants/forms'
import ImportActionsModal from 'components/ImportActionsModal'
import { IconMenu, MenuItem } from 'react-toolbox/lib/menu'
import styles from './CardList.scss'

const propTypes = {
  className: PropTypes.string,
  contentExtractor: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  header: PropTypes.node,
  isCustomReport: PropTypes.bool,
  itemClassName: PropTypes.string,
  keyExtractor: PropTypes.func.isRequired,
  noItemPadding: PropTypes.bool,
  nToLoad: PropTypes.number,
  onClick: PropTypes.func,
  onLoadMore: PropTypes.func,
  showImportActionButton: PropTypes.bool,
}

const defaultProps = {
  isCustomReport: false,
  showImportActionButton: false,
  className: null,
  disabled: false,
  header: null,
  itemClassName: null,
  noItemPadding: false,
  nToLoad: 0,
  onClick: null,
  onLoadMore: null,
}

function CardList({
  isCustomReport,
  className, disabled, header, data, keyExtractor, contentExtractor,
  onClick, itemClassName, noItemPadding,
  nToLoad, onLoadMore, showImportActionButton,
}) {
  const dispatch = useDispatch()
  const modals = useSelector(state => state.modals)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleImportActionsClick = (e) => {
    dispatch(modalShow(MODAL_IMPORT_ACTIONS))
    e.stopPropagation()
  }

  const createModal = useCallback(() => {
    if (modals && !modals.modalNames.includes(MODAL_IMPORT_ACTIONS)) {
      dispatch(modalCreate(MODAL_IMPORT_ACTIONS))
    }
  }, [dispatch, modals])

  const clickable = onClick && !disabled

  return (
    <div>
      {isCustomReport ? (
        <div>
          {data.map((item) => {
            return (
              <Card
                className={styles.cusRepBase}
                onClick={clickable ? () => onClick(item) : () => { }}
              >
                {contentExtractor(item)}
              </Card>
            )
          })}
        </div>
      ) : (
        <div>
          <Card noPadding className={className}>
            {header}
            <ul className={styles.base}>
              {data.map((item) => {
                return (
                  <li
                    className={classnames(
                      styles.item,
                      itemClassName,
                      noItemPadding ? null : styles.padding,
                      clickable ? styles.clickable : null,
                      disabled ? styles.disabled : null,
                    )}
                    key={keyExtractor(item)}
                  >
                    <a
                      onClick={clickable ? () => onClick(item) : () => { }}
                    >
                      {contentExtractor(item)}
                      {clickable && (
                        <IconMenu
                          menuRipple
                          className={styles.menuRipple}
                          icon="more_vert"
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                          }}
                          position="topRight"
                        >
                          <MenuItem
                            caption="Import Actions"
                            onClick={(e) => {
                              e.stopPropagation()
                              setSelectedItem(item)
                              createModal()
                              handleImportActionsClick(e)
                            }}
                            value="Import Actions"
                          />
                        </IconMenu>
                      )}
                    </a>
                  </li>
                )
              })}
            </ul>
            {onLoadMore && nToLoad > 0 && (
              <div className={styles.loadMore}>
                <a onClick={onLoadMore}>
                  <Icons.IconArrowDownward className={styles.arrow} height={24} width={24} />
                  {` LOAD ${nToLoad || ''} MORE`}
                </a>
              </div>
            )}
          </Card>
        </div>
      )}
      {showImportActionButton && selectedItem && <ImportActionsModal action={selectedItem} />}
    </div>
  )
}

CardList.propTypes = propTypes
CardList.defaultProps = defaultProps

export default CardList
