import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import TabPanel from 'components/TabPanel'
import { Tabs, Button } from '@deloitte/gel-library'
import { Divider } from '@material-ui/core'
import { push } from 'connected-react-router'
import useAction from 'actionHub/hooks/useAction'
import styles from './ReviewSection.scss'
import CaseTestingConclusion from '../../../components/CaseTestingConclusion'
import Comments from '../Comments'
import useTask from '../../hooks/useTask'
import useApp from '../../../hooks/useApp'
import { fetchSupportingData } from '../../../actions/job'

const ReviewSection = ({
  actionSetId,
  taskId,
  comments = [],
  disabled = false,
  object,
  setLabelValue,
  submitLabels,
  validateLabel,
  hasExpired,
  jobId,
  isAssignedActionsEditor,
}) => {
  const dispatch = useDispatch()
  const isExternal = useSelector(state => state.app.user.isExternal)
  const { allowComments = false } = useSelector(state => state.actionHub.taskLevel)
  const task = useSelector(state => state.actionHub?.tasks[taskId] || {})
  const [tabValue, setTabValue] = useState(0)
  const { removeAssignedTask, taskTitle, setTaskCommentStatus } = useTask(actionSetId, taskId)
  const { showNotification } = useApp()
  const [isCompleting, setIsCompleting] = useState(false)
  const { fetchAction, isCurrent } = useAction(actionSetId)

  useEffect(() => {
    dispatch(fetchSupportingData(jobId, isExternal, actionSetId, taskId))
  }, [dispatch, jobId, isExternal, actionSetId, taskId])

  const practitionerComments = comments.filter(comment => comment?.commentTypeId === 0)

  const handleMarkComplete = async () => {
    setIsCompleting(true)

    await Promise.all([
      setTaskCommentStatus(3),
      removeAssignedTask(),
      fetchAction(),
    ])
    dispatch(push(`/action/${actionSetId}`))
    showNotification(`${taskTitle} has been returned to the Deloitte team. We will notify you if further action is required.`)
  }

  if (isAssignedActionsEditor) {
    const externalCommentCount = comments?.filter(comment => comment.commentTypeId === 1 || comment.commentTypeId === 2).length
    return (
      <div className={styles.base}>
        <div className={styles.commentsContainer}>
          <div className={styles.commentsPadding}>
            <div className={styles.commentsHeading}>
              Comments
              <span className={styles.itemCount}>
                {`${externalCommentCount} item${externalCommentCount === 1 ? '' : 's'}`}
              </span>
            </div>
            <Comments
              actionSetId={actionSetId}
              commentType={1}
              disabled={!isCurrent || hasExpired}
              jobId={jobId}
              taskId={taskId}
              variant={{ attachable: true }}
            />
          </div>
        </div>
        <div className={styles.buttonRow}>
          <div className={styles.footerContent}>
            <div />
            <Button
              disabled={isCompleting || task?.commentStatusId === 3}
              onClick={handleMarkComplete}
              style={{ padding: '6px 12px' }}
            >
              MARK COMPLETE AND SAVE
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const tabLabels = allowComments
    ? [
      <span className={styles.tabHeading}>REVIEW FINDINGS</span>,
      <div className={styles.practitionerCommentsContainer}>
        <span className={styles.tabHeading}>PRACTITIONER COMMENTS</span>
        <span className={styles.circle}>{practitionerComments.length}</span>
      </div>,
    ]
    : ['REVIEW FINDINGS']

  return (
    <div className={styles.base}>
      <Tabs
        isDarkTheme
        className={styles.tabs}
        onTabChange={setTabValue}
        tabLabels={tabLabels}
        value={tabValue}
      />
      <Divider />
      <TabPanel className={styles.tabPanel} index={0} value={tabValue}>
        <CaseTestingConclusion
          actionSetId={actionSetId}
          comments={comments}
          disabled={disabled}
          jobId={jobId}
          object={object}
          setLabelValue={setLabelValue}
          submitLabels={submitLabels}
          taskId={taskId}
          validateLabel={validateLabel}
        />
      </TabPanel>
      {allowComments && (
        <TabPanel className={styles.tabPanel} index={1} value={tabValue}>
          <div className={styles.commentsPadding}>
            <Comments
              actionSetId={actionSetId}
              commentType={0}
              disabled={disabled}
              jobId={jobId}
              taskId={taskId}
              variant={{ attachable: true }}
            />
          </div>
        </TabPanel>
      )}
    </div>
  )
}

ReviewSection.propTypes = {
  actionSetId: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  hasExpired: PropTypes.bool.isRequired,
  isAssignedActionsEditor: PropTypes.bool.isRequired,
  jobId: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  setLabelValue: PropTypes.func.isRequired,
  submitLabels: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
  validateLabel: PropTypes.func.isRequired,
}

ReviewSection.defaultProps = {
  comments: [],
  disabled: false,
}

export default ReviewSection
