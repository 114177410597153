import React, { useRef, useState } from 'react'
import PropTypes, { object } from 'prop-types'
import classNames from 'classnames'
import { DropdownMenu } from '@deloitte/gel-library'
import { userPropType } from 'utils/propTypes'
import User from 'components/User'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Popover } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { notifyReleaseToClient, putTasksCommentStatus } from 'actionHub/utils/actionHubApi'
import { submittedTaskCommentStatus, actionHubUpdateTasksWithClient } from 'actionHub/redux/actions'
import useApp from 'hooks/useApp'
import styles from './ActionBar.scss'
import TickmarkGenerator from '../TickmarkGenerator'

function ActionBar({
  actionStates,
  deSelectAll,
  isVisible,
  selectedTaskIds,
  members,
  onHandleOpenModal,
  setTasksAssigned,
  setTasksActionState,
  actionSetId,
  clients,
}) {
  const dispatch = useDispatch()
  const allTasks = useSelector(state => state.actionHub.tasks)
  const { showNotification } = useApp()

  const unassignedUser = {
    userDetails: {
      firstName: 'Unassigned',
      surname: '',
      email: '',
    },
  }

  const userList = (users, assigneeType) => ([
    ...users.map(u => ({
      key: u.id,
      label: <User showEmail key={u.id} user={u} />,
      onClick: () => {
        setTasksAssigned(selectedTaskIds, u.id, assigneeType)
        deSelectAll()
      },
    })),
    {
      key: '',
      label: <User user={unassignedUser} />,
      onClick: () => setTasksAssigned(selectedTaskIds, null, assigneeType),
    },
  ])

  const actions = actionStates.map(as => ({
    key: `${as.id}`,
    label: as.description,
    onClick: () => {
      setTasksActionState(selectedTaskIds, as.id)
      deSelectAll()
    },
  }))

  const disabled = selectedTaskIds.length === 0

  const buttonRef = useRef(null)
  const { allowTags } = useSelector(state => state.actionHub)
  const { allowClientComments = false } = useSelector(state => state.actionHub.taskLevel)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = () => {
    setAnchorEl(buttonRef.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleApply = () => {
    setAnchorEl(null)
    deSelectAll()
  }

  const handleReleaseToClient = () => {
    const tasksToUpdate = selectedTaskIds.filter((id) => {
      const task = allTasks[id]
      return task.commentStatusId === 1
    })

    const numTasks = tasksToUpdate.length
    if (numTasks > 0) {
      dispatch(submittedTaskCommentStatus(actionSetId, tasksToUpdate, 2))

      putTasksCommentStatus(actionSetId, tasksToUpdate, 2)
        .then(() => {
          const clientList = []
          tasksToUpdate.forEach((id) => {
            const clientTeamId = allTasks[id]?.clientTeamId
            if (clientTeamId && !clientList.includes(clientTeamId)) {
              clientList.push(clientTeamId)
              notifyReleaseToClient(clientTeamId)
                .catch((e) => {
                  showNotification('Failed to notify user', e, 'error')
                })
            }
          })

          const message = numTasks === 1
            ? '1 action was released to client portal'
            : `${numTasks} actions were released to client portal`
          showNotification(message)

          dispatch(actionHubUpdateTasksWithClient(numTasks))
        })
        .catch((e) => {
          tasksToUpdate.forEach((id) => {
            const currentTask = allTasks[id]
            const previousCommentStatusId = currentTask.commentStatusId
            dispatch(submittedTaskCommentStatus(actionSetId, [id], previousCommentStatusId))
          })
          showNotification('Could not save comment status, please refresh the page and contact support if issues persist.', e, 'error')
        })
    }
    deSelectAll()
  }

  const assigneeList = [
    {
      users: members,
      key: 'assignedTo',
      label: 'PRACTITIONER',
    },
    ...(allowClientComments ? [
      {
        users: clients,
        key: 'clientTeamId',
        label: 'CLIENT',
      },
      {
        users: members,
        key: 'reviewerId',
        label: 'REVIEWER',
      },
    ] : []),
  ]

  return (
    <div className={classNames(styles.base,
      disabled ? styles.disabled : null,
      isVisible ? null : styles.hidden)}
    >
      <div className={styles.selectedCount}>
        {selectedTaskIds.length > 1 && `${selectedTaskIds.length} items selected`}
        {selectedTaskIds.length === 1 && `${selectedTaskIds.length} item selected`}
      </div>
      <div className={styles.spacer} />
      <div className={styles.actionButton} onClick={() => onHandleOpenModal(true)}>
        + UPDATE ROWS
      </div>
      <span className={styles.separator} />
      <div>
        <DropdownMenu displayText="SET ACTION STATE" options={actions} />
      </div>
      {assigneeList.map(assignee => (
        <div key={assignee.key}>
          <DropdownMenu
            displayText={`ASSIGN TO ${assignee.label}`}
            options={userList(assignee.users, assignee.key)}
          />
        </div>
      ))}
      {allowTags && (
        <div className={classNames(styles.manageTick, anchorEl !== null ? styles.active : null)}>
          <div onClick={handleClick}>
            MANAGE TICKMARK
          </div>
          <ArrowDropDownIcon
            onClick={handleClick}
            ref={buttonRef}
          />
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={handleClose}
            open={open}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <TickmarkGenerator
              actionSetId={actionSetId}
              onApply={handleApply}
              onClose={handleClose}
              selectedTaskIds={selectedTaskIds}
            />
          </Popover>
        </div>
      )}
      {allowClientComments && (
        <div className={styles.actionButton} onClick={handleReleaseToClient}>
          RELEASE TO CLIENT
        </div>
      )}
      <span className={styles.separator} />
      <div className={styles.actionButton} onClick={deSelectAll}>
        DESELECT ALL
      </div>
    </div>
  )
}

ActionBar.propTypes = {
  actionSetId: PropTypes.string.isRequired,
  actionStates: PropTypes.arrayOf(object).isRequired,
  clients: PropTypes.arrayOf(userPropType).isRequired,
  deSelectAll: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  members: PropTypes.arrayOf(userPropType).isRequired,
  onHandleOpenModal: PropTypes.func.isRequired,
  selectedTaskIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setTasksActionState: PropTypes.func.isRequired,
  setTasksAssigned: PropTypes.func.isRequired,
}

ActionBar.defaultProps = {
  isVisible: false,
}

export default ActionBar

