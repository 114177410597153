/* eslint-disable no-param-reassign */

import { push } from 'connected-react-router'
import filter from 'lodash/filter'
import map from 'lodash/map'
import some from 'lodash/some'
import forEach from 'lodash/forEach'
import isBoolean from 'lodash/isBoolean'
import isArray from 'lodash/isArray'
import axios from 'axios'
import FileDownload from 'js-file-download'
import {
  deleteRequest, getRequest, postRequest, postRequestBinaryFile, putRequest,
} from 'utils/api'
import constants from 'constants'
import moment from 'moment'
import { optionsFormat } from 'utils/form'
import { formDestroy, formUpdate } from 'actions/forms'
import { updateJob as updateJobinList } from 'actions/jobs'
import {
  getSerializedFormState, handleError, handleErrors, requiredFields, validateAll,
} from 'utils/validators'
import { getConfig } from 'utils/config'

import { extractResponseParameters } from 'utils/apiresponse'
import { decorateJob, downloadStatuses, processJobMembers } from 'utils/business/jobs'
import { processTableVariableData, schemaWithTests } from 'utils/business/parameters'
import { jobDataIsValidated } from 'utils/business/workflow'
import {
  deleteDataPrepRequest,
  getAllFileGroups,
  getAllSupportingFiles,
  getJobActions,
  getJobAssignableExternalRoles,
  getJobCurrentActions,
  getJobDataRecipes,
  getJobDownloads,
  getJobDownloadStatus,
  getJobMembers,
  getJobParameters,
  getJobReports,
  getJobRequiredTables,
  getJobSampleDataJson,
  getJobSampleDataZip,
  getJobTestParameters,
  getSingleJob,
  getTargetSchemas,
  importPrevAction,
  patchUpdateJob,
  patchUpdateJobName,
  postAnalyses,
  postBenchmarkFlag,
  postFileGroup,
  postJob,
  postJobMembers,
  postJobPackageParameters,
  postJobParameters,
  postLaunchWorkbench,
  postParameters,
  putDataPrepRequest,
  putStartDownload,
  putStartExecution,
  putUpdateDownloadStatus,
  sendDataPrepEmail,
} from 'utils/api/job'
import { getConnectedJobCurrentActionSetIds, postActions } from 'actionHub/utils/actionHubApi'
import { postCommitData } from 'utils/api/file'
import { isExternalFromState } from 'utils/users'
import { getCurrentDisplayValues, isConditionallyDisabledFromParameterDisplay } from 'actionHub/utils/dependencies'
import { getPostAnalysisSelectionLink } from 'hooks/useJobCalculated'
import {
  actionHubErroredImportingActions,
  actionHubImportedActions,
  actionHubImportingActions,
} from 'actionHub/redux/actions'
import { makeDate } from 'utils/dates'
import { deleteFileGroup, postFileToFileGroup } from 'utils/api/fileGroup'
import {
  apiError, clearNotification, notify, progressModel,
} from './app'
import {
  addFileError, deleteFile, updateStatus, updateUploaderFileStatus, updateUploaders,
} from './uploaders'
import { formSubmit, formSubmitClear, formSubmitComplete } from './forms'
import { modalHide } from './modals'
import { updateFileGroup } from '../utils/api/job'
import { sanitizeInput } from '../utils/form'

export const FETCHED_CONNECTED_JOBS = 'FETCHED_CONNECTED_JOBS'
export const JOB_RESET = 'JOB_RESET'
export const JOB_UPDATE_SELECTED_TEST = 'JOB_UPDATE_SELECTED_TEST'
export const CLONED_JOB_RESET_CLONING_STATUS = 'CLONED_JOB_RESET_CLONING_STATUS'
export const CLONED_JOB_NEW_JOB_ID = 'CLONED_JOB_NEW_JOB_ID'
export const JOB_SAVING = 'JOB_SAVING'
export const JOB_SAVED = 'JOB_SAVED'
export const JOB_RESET_CTA = 'JOB_RESET_CTA'
export const JOB_FILE_UPDATE = 'JOB_FILE_UPDATE'
export const JOB_UPDATE_JOB = 'JOB_UPDATE_JOB'
export const LAUNCHED_WORKBENCH = 'LAUNCHED_WORKBENCH'
export const LAUNCHING_WORKBENCH = 'LAUNCHING_WORKBENCH'
export const JOB_FETCHED_TARGET_SCHEMAS = 'JOB_FETCHED_TARGET_SCHEMAS'
export const JOB_FETCHING_JOB = 'JOB_FETCHING_JOB'
export const JOB_FETCHING_JOB_DETAILS = 'JOB_FETCHING_JOB_DETAILS'
export const JOB_FETCHED_JOB_DETAILS = 'JOB_FETCHED_JOB_DETAILS'
export const JOB_FETCHED_JOB_TO_BE_CLONED_DETAILS = 'JOB_FETCHED_JOB_TO_BE_CLONED_DETAILS'
export const JOB_UPLOADING = 'JOB_UPLOADING'
export const DATA_PREP_REQUIRED = 'DATA_PREP_REQUIRED'
export const DATA_PREP_REQUEST_UPDATED = 'DATA_PREP_REQUEST_UPDATED'
export const FILE_GROUPS_UPDATED = 'FILE_GROUPS_UPDATED'
export const SUPPORTING_FILES_GROUP = 'SUPPORTING_FILES_GROUP'
export const SUPPORTING_FILES_GROUP_STATUS = 'SUPPORTING_FILES_GROUP_STATUS'
export const JOB_FETCHING_REPORTS = 'JOB_FETCHING_REPORTS'
export const FILE_GROUPS_FILES_UPLOAD_STATUS = 'FILE_GROUPS_FILES_UPLOAD_STATUS'
export const JOB_FETCHED_REPORTS = 'JOB_FETCHED_REPORTS'
export const JOB_ERROR_FETCHING_REPORTS = 'JOB_ERROR_FETCHING_REPORTS'
export const FILE_GROUPS_FILE_UPLOAD_STATUS = 'FILE_GROUPS_FILE_UPLOAD_STATUS'
export const JOB_REPUBLISHED_REPORTS = 'JOB_REPUBLISHED_REPORTS'
export const JOB_DATA_DOWNLOAD_START = 'JOB_DATA_DOWNLOAD_START'
export const JOB_DATA_DOWNLOAD_COMPLETE = 'JOB_DATA_DOWNLOAD_COMPLETE'
export const JOB_SETTING_PARAMETERS = 'JOB_SETTING_PARAMETERS'
export const JOB_SETTING_CATEGORY_PARAMETERS = 'JOB_SETTING_CATEGORY_PARAMETERS'
export const JOB_SETTING_SOLUTION_PARAMETERS = 'JOB_SETTING_SOLUTION_PARAMETERS'
export const JOB_SOLUTION_PARAMETERS_UPDATED = 'JOB_SOLUTION_PARAMETERS_UPDATED'
export const JOB_FINISHED_SETTING_PARAMETERS = 'JOB_FINISHED_SETTING_PARAMETERS'
export const JOB_FINISHED_SETTING_CATEGORY_PARAMETERS = 'JOB_FINISHED_SETTING_CATEGORY_PARAMETERS'
export const JOB_IGNORE_ERRORS = 'JOB_IGNORE_ERRORS'
export const START_SPINNING_VISUALISATION = 'START_SPINNING_VISUALISATION'
export const JOB_FETCHING_SAMPLE_DATA_JSON = 'JOB_FETCHING_SAMPLE_DATA_JSON'
export const JOB_FETCHED_SAMPLE_DATA_JSON = 'JOB_FETCHED_SAMPLE_DATA_JSON'
export const JOB_CHANGED_SELECTED_SAMPLE_TABLE = 'JOB_CHANGED_SELECTED_SAMPLE_TABLE'
export const JOB_VALIDATING_DATA_START = 'JOB_VALIDATING_DATA_START'
export const FINISH_SPINNING_VISUALISATION = 'FINISH_SPINNING_VISUALISATION'
export const JOB_VALIDATING_DATA_END = 'JOB_VALIDATING_DATA_END'
export const JOB_UPLOADING_FILES_START = 'JOB_UPLOADING_FILES_START'
export const JOB_UPLOADING_FILES_END = 'JOB_UPLOADING_FILES_END'
export const JOB_REQUEST_EXECUTE_START = 'JOB_REQUEST_EXECUTE_START'
export const JOB_FETCHING_DATA_REQUIREMENTS = 'JOB_FETCHING_DATA_REQUIREMENTS'
export const JOB_RECEIVED_DATA_REQUIREMENTS = 'JOB_RECEIVED_DATA_REQUIREMENTS'
export const JOB_ADD_JOB_MEMBERS = 'JOB_ADD_JOB_MEMBERS'
export const JOB_REMOVE_JOB_MEMBER = 'JOB_REMOVE_JOB_MEMBER'
export const JOB_UPDATED_JOB_MEMBERS = 'JOB_UPDATED_JOB_MEMBERS'
export const JOB_FETCHING_JOB_MEMBERS = 'JOB_FETCHING_JOB_MEMBERS'
export const JOB_FETCHED_JOB_MEMBERS = 'JOB_FETCHED_JOB_MEMBERS'
export const JOB_UPDATE_JOB_REPORT = 'JOB_UPDATE_JOB_REPORT'
export const JOB_FETCHING_ACTIONS = 'JOB_FETCHING_ACTIONS'
export const JOB_FETCHED_ACTIONS = 'JOB_FETCHED_ACTIONS'
export const JOB_ERROR_FETCHING_ACTIONS = 'JOB_ERROR_FETCHING_ACTIONS'
export const JOB_FETCHING_CURRENT_ACTIONS = 'JOB_FETCHING_CURRENT_ACTIONS'
export const JOB_FETCHED_CURRENT_ACTIONS = 'JOB_FETCHED_CURRENT_ACTIONS'
export const JOB_CREATING_JOB_ACTIONS = 'JOB_CREATING_JOB_ACTIONS'
export const JOB_CREATED_JOB_ACTIONS = 'JOB_CREATED_JOB_ACTIONS'
export const JOB_UPDATE_JOB_DOWNLOAD = 'JOB_UPDATE_JOB_DOWNLOAD'
export const JOB_MODIFYING_USER_ROLE = 'JOB_MODIFYING_USER_ROLE'
export const JOB_MODIFIED_USER_ROLE = 'JOB_MODIFIED_USER_ROLE'
export const JOB_FETCHED_EXTERNAL_ROLES = 'JOB_FETCHED_EXTERNAL_ROLES'

const fetchingJob = fetching => ({
  type: JOB_FETCHING_JOB,
  fetching,
})

const fetchingJobDetails = jobId => ({
  type: JOB_FETCHING_JOB_DETAILS,
  jobId,
})

const fetchedConnectedJobs = connectedJobsActions => ({
  type: FETCHED_CONNECTED_JOBS,
  connectedJobsActions,
})

const fetchedJobDetails = job => ({
  type: JOB_FETCHED_JOB_DETAILS,
  job,
})

const fetchedJobToBeCloned = job => ({
  type: JOB_FETCHED_JOB_TO_BE_CLONED_DETAILS,
  job,
})

export const updateDataPrepRequiredChoice = isDataReady => ({
  type: DATA_PREP_REQUIRED,
  isDataReady,
})

export const updateDataPrepAdded = dataPreparationRequest => ({
  type: DATA_PREP_REQUEST_UPDATED,
  dataPreparationRequest,
})

export const updateFileGroups = fileGroups => ({
  type: FILE_GROUPS_UPDATED,
  fileGroups,
})

export const updateSupportingFiles = files => ({
  type: SUPPORTING_FILES_GROUP,
  files,
})

export const updateSupportingFilesStatus = fileStatus => ({
  type: SUPPORTING_FILES_GROUP_STATUS,
  fileStatus,
})

export const updateFileGroupFilesUploadStatus = fileGroupFilesUploadStatus => ({
  type: FILE_GROUPS_FILES_UPLOAD_STATUS,
  fileGroupFilesUploadStatus,
})

export const updateFileGroupFileUploadStatus = fileGroupFileUploadStatus => ({
  type: FILE_GROUPS_FILE_UPLOAD_STATUS,
  fileGroupFileUploadStatus,
})

const fetchingSampleDataJson = () => ({
  type: JOB_FETCHING_SAMPLE_DATA_JSON,
})

const fetchedSampleDataJson = data => ({
  type: JOB_FETCHED_SAMPLE_DATA_JSON,
  data,
})

export const changeSelectedSampleTable = index => ({
  type: JOB_CHANGED_SELECTED_SAMPLE_TABLE,
  index,
})

export const sending = () => ({
  type: JOB_SAVING,
})

export const savingSolutionParam = () => ({
  type: JOB_SETTING_SOLUTION_PARAMETERS,
})

const saved = () => ({
  type: JOB_SAVED,
})

const solutionParamUpdated = () => ({
  type: JOB_SOLUTION_PARAMETERS_UPDATED,
})

export const resetCTA = () => ({
  type: JOB_RESET_CTA,
})

export const resetCloningState = () => ({
  type: CLONED_JOB_RESET_CLONING_STATUS,
})

export const reset = formData => ({
  type: JOB_RESET,
  formData,
})

export const updateSelected = selected => ({
  type: JOB_UPDATE_SELECTED_TEST,
  selected,
})

export const updateJob = job => ({
  type: JOB_UPDATE_JOB,
  job,
})

export const fetchedTargetSchemas = availableTargetSchemas => ({
  type: JOB_FETCHED_TARGET_SCHEMAS,
  availableTargetSchemas,
})

export const updateClonedJobNewJobId = jobId => ({
  type: CLONED_JOB_NEW_JOB_ID,
  jobId,
})

export const fetchingReports = jobId => ({
  type: JOB_FETCHING_REPORTS,
  jobId,
})

export const fetchedReports = (reports, jobId) => ({
  type: JOB_FETCHED_REPORTS,
  reports,
  jobId,
})

export const errorFetchingReports = error => ({
  type: JOB_ERROR_FETCHING_REPORTS,
  error,
})

const republishedReports = jobId => ({
  type: JOB_REPUBLISHED_REPORTS,
  jobId,
})

export const settingTestParameters = () => ({
  type: JOB_SETTING_PARAMETERS,
})

export const settingCategoryTestParameters = () => ({
  type: JOB_SETTING_CATEGORY_PARAMETERS,
})

export const settingSolutionParameters = () => ({
  type: JOB_SETTING_SOLUTION_PARAMETERS,
})

export const finishedSettingTestParameters = () => ({
  type: JOB_FINISHED_SETTING_PARAMETERS,
})

export const finishedSettingCategoryTestParameters = () => ({
  type: JOB_FINISHED_SETTING_CATEGORY_PARAMETERS,
})

export const setIgnoreErrors = ignore => ({
  type: JOB_IGNORE_ERRORS,
  ignore,
})

export const startedDataValidation = () => ({
  type: JOB_VALIDATING_DATA_START,
})

export const startedSpinningVisualisation = () => ({
  type: START_SPINNING_VISUALISATION,
})

export const startedUploadingFiles = () => ({
  type: JOB_UPLOADING_FILES_START,
})

export const launchingWorkbench = () => ({
  type: LAUNCHING_WORKBENCH,
})

export const endUploadingFiles = () => ({
  type: JOB_UPLOADING_FILES_END,
})

export const launchedWorkbench = () => ({
  type: LAUNCHED_WORKBENCH,
})

export const finishedDataValidation = () => ({
  type: JOB_VALIDATING_DATA_END,
})

export const finishedSpinningVisualisation = () => ({
  type: FINISH_SPINNING_VISUALISATION,
})

export const requestJobExecution = () => ({
  type: JOB_REQUEST_EXECUTE_START,
})

export const requestDataRequirements = () => ({
  type: JOB_FETCHING_DATA_REQUIREMENTS,
})

export const receivedDataRequirements = () => ({
  type: JOB_RECEIVED_DATA_REQUIREMENTS,
})

export const addJobMembers = (jobId, members) => ({
  type: JOB_ADD_JOB_MEMBERS,
  jobId,
  members,
})

const removeJobMember = (jobId, userId) => ({
  type: JOB_REMOVE_JOB_MEMBER,
  jobId,
  userId,
})

const fetchingJobMembers = jobId => ({
  type: JOB_FETCHING_JOB_MEMBERS,
  jobId,
})

const fetchedJobMembers = (jobId, userId, members) => ({
  type: JOB_FETCHED_JOB_MEMBERS,
  jobId,
  processedMembers: processJobMembers(userId, members),
})

const fetchedExternalRoles = roles => ({
  type: JOB_FETCHED_EXTERNAL_ROLES,
  roles,
})

export const initUploaders = (jobId, files) => {
  const uploaders = {}
  files.forEach((file) => {
    if (!uploaders[file.tableCode]) {
      uploaders[file.tableCode] = {
        isOptional: file.isOptional,
        id: file.tableCode,
        name: file.tableName,
        columns: file.columns,
        dataRecipe: file.dataRecipe,
        description: file.tableDescription,
        files: {},
        errors: [],
        rowCount: +file.rowCount,
        lastUploaded: moment(file.dateLastUploaded),
        isDataCurrent: file.isDataCurrent,
        isRequiredColumnsPresent: file.isRequiredColumnsPresent,
      }
    }

    if (file.rawTableName && file.isDataCurrent && file.isRequiredColumnsPresent) {
      file.rawTableName.split(',').forEach((filename) => {
        const fileid = `${jobId}_${file.tableCode}_${filename}`
        uploaders[file.tableCode].files[fileid] = {
          fileId: fileid,
          name: filename.trim(),
          status: 'saved',
          rows: [],
          tableHeaders: [],
        }
      })
    }
  })

  if (files.length === 0) {
    return Promise.resolve([])
  }

  return Promise.resolve(uploaders)
}

const processParameter = parameter => ({
  ...parameter,
  id: parameter.id !== 0 ? parameter.id : parameter.variableName,
  value:
    parameter.type === 'TABLE'
      ? processTableVariableData(parameter.value)
      : parameter.value,
  schema: parameter.type === 'TABLE' ? parameter.schema : null,
  options: isArray(parameter.options)
    ? parameter.options.map(value => ({
      value,
      label: value,
    }))
    : [],
})

const processTestParameters = (data) => {
  // Test params
  const testParameters = {}
  Object.keys(data).forEach((analysisId) => {
    const lowerCasedAnalysisId = analysisId.toLowerCase()
    const testParameter = data[analysisId]

    if (testParameter.length === 0) {
      return
    }

    testParameters[lowerCasedAnalysisId] = testParameter.map(processParameter)
  })

  return testParameters
}

const getParameters = ({ jobId }) => {
  return axios
    .all([
      getJobParameters(jobId),
      getJobTestParameters(jobId),
    ])
    .then(
      axios.spread(({ data: packageData }, { data: testData }) => {
        // Package params
        const packageParameters = packageData.map(processParameter)

        const testParameters = processTestParameters(testData)

        return Promise.resolve({
          package: packageParameters,
          test: testParameters,
        })
      }),
    )
}

const jobFetchingActions = () => ({
  type: JOB_FETCHING_ACTIONS,
})

const jobFetchedActions = actions => ({
  type: JOB_FETCHED_ACTIONS,
  actions,
})

const jobErrorFetchingActions = error => ({
  type: JOB_ERROR_FETCHING_ACTIONS,
  error,
})

const handleFetchActionsError = (dispatch, error) => {
  if (error.response && error.response.status === 403) {
    // User not permitted to see actions
    dispatch(jobErrorFetchingActions(error))
  } else {
    // Generic error handling
    dispatch(notify('Failed to fetch actions; please contact support', 'error'))
    console.error('Error fetching actions:', error)
  }
}

export function fetchActions({ jobId }) {
  return async (dispatch) => {
    dispatch(jobFetchingActions())
    try {
      const { data } = await getJobActions(jobId)
      dispatch(jobFetchedActions(data))
    } catch (e) {
      handleFetchActionsError(dispatch, e)
    }
  }
}

const jobFetchingCurrentActions = jobId => ({
  type: JOB_FETCHING_CURRENT_ACTIONS,
  jobId,
})

const jobFetchedCurrentActions = (jobId, currentActions) => ({
  type: JOB_FETCHED_CURRENT_ACTIONS,
  jobId,
  currentActions,
})

export function fetchCurrentActions({ jobId }) {
  return (dispatch) => {
    dispatch(jobFetchingCurrentActions(jobId))
    getJobCurrentActions(jobId)
      .then(({ data }) => {
        dispatch(jobFetchedCurrentActions(jobId, data))
      })
  }
}

const startCreatingJobActions = () => ({
  type: JOB_CREATING_JOB_ACTIONS,
})

const finishCreatingJobActions = () => ({
  type: JOB_CREATED_JOB_ACTIONS,
})

export function createJobActions({ jobId }) {
  return (dispatch) => {
    dispatch(startCreatingJobActions())
    postActions(jobId)
      .then(() => {
        dispatch(fetchActions({ jobId })).then(() => {
          dispatch(finishCreatingJobActions())
        })
      })
      .catch(() => {
        dispatch(notify('Could not create actions, please try again later or contact support', 'error'))
        dispatch(finishCreatingJobActions())
      })
  }
}

export const fetchReports = (jobId) => {
  return (dispatch, getState) => {
    dispatch(fetchingReports(jobId))
    return getJobReports(jobId, isExternalFromState(getState()))
      .then(({ data: reports }) => {
        dispatch(fetchedReports(reports, jobId))
        return Promise.resolve()
      })
      .catch((e) => {
        dispatch(errorFetchingReports(e))
        return Promise.reject(new Error('Error fetching reports'))
      })
  }
}

export const updateJobDownload = (jobId, downloadName, download) => ({
  type: JOB_UPDATE_JOB_DOWNLOAD,
  jobId,
  downloadName,
  download,
})

export function importActions(jobId, currentActionSetId, actionIdToImport) {
  return (dispatch, getState) => {
    dispatch(actionHubImportingActions())
    return importPrevAction(currentActionSetId, actionIdToImport)
      .then(() => {
        dispatch(actionHubImportedActions())
        if (getState().job.downloads.length > 0) {
          const currentJobDownloadName = getState().job.downloads[0].name
          putUpdateDownloadStatus(jobId, currentJobDownloadName, downloadStatuses.notStarted)
            .then(() => {
              dispatch(updateJobDownload(jobId, currentJobDownloadName, { status: downloadStatuses.notStarted }))
            })
            .catch(() => {
              dispatch(notify('Failed to update download status; please contact support', 'error'))
            })
        }
        dispatch(notify('Actions imported successfully'))
        dispatch(fetchActions({ jobId }))
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          dispatch(actionHubImportedActions())
          dispatch(notify('No data found to import from this job', 'error'))
        } else {
          dispatch(actionHubErroredImportingActions())
          dispatch(notify('Failed to import actions; please contact support', 'error'))
        }
      })
  }
}

export function fetchConnectedJobs(jobId, name, type) {
  return (dispatch) => {
    return getConnectedJobCurrentActionSetIds(jobId, name, type)
      .then(({ data }) => {
        dispatch(fetchedConnectedJobs(data))
      })
      .catch((e) => {
        console.error('Encountered following error while fetching connected jobs: ', e)
      })
  }
}

export function cloneJobDetails(jobId) {
  return (dispatch, getState) => {
    const isExternal = isExternalFromState(getState())
    Promise
      .all([
        getSingleJob(jobId, isExternal),
        getJobTestParameters(jobId, isExternal),
        getJobParameters(jobId, isExternal),
        getJobDataRecipes(jobId, isExternal),
      ])
      .then(
        axios.spread(
          (
            { data: job },
            { data: testParameters },
            { data: packageParameters },
            { data: recipes },
          ) => {
            dispatch(fetchedJobToBeCloned({
              ...job,
              analyses: job.analyses.map(a => ({
                ...a,
                parameters: processTestParameters(testParameters)[a.id],
              })),
              recipes,
              packageParameters: packageParameters.map(processParameter),
            })).then(
              dispatch(push(`/createjob/${true}`)),
            )
          },
        ),
      )
      .catch((e) => {
        dispatch(fetchingJob(false))
        dispatch(apiError(e))
      })
  }
}

export function fetchJobDetails({ jobId, includeColumns = false }) {
  return (dispatch, getState) => {
    // Bail if we've already started fetching job details elsewhere
    if (getState().job._fetchingJobDetailsId) {
      return
    }

    const isExternal = isExternalFromState(getState())
    dispatch(fetchingJobDetails(jobId))

    Promise
      .all([
        getSingleJob(jobId, isExternal),
        getJobMembers(jobId, isExternal),
        getJobRequiredTables({ jobId, isExternal, includeColumns }),
        getJobTestParameters(jobId, isExternal),
        getJobParameters(jobId, isExternal),
        getJobDownloads(jobId, isExternal),
        getJobDataRecipes(jobId, isExternal),
      ])
      .then(
        axios.spread(
          (
            { data: job },
            { data: members },
            { data: files },
            { data: testParameters },
            { data: packageParameters },
            { data: downloads },
            { data: recipes },
          ) => {
            const { app: { user: { id: userId } } } = getState()
            dispatch(fetchedJobDetails({
              ...job,
              analyses: job.analyses.map(a => ({
                ...a,
                parameters: processTestParameters(testParameters)[a.id.toLowerCase()],
              })),
              downloads,
              files,
              recipes,
              packageParameters: packageParameters.map(processParameter),
              ...processJobMembers(userId, members),
            }))
            dispatch(fetchingJob(false))
          },
        ),
      )
      .catch((e) => {
        dispatch(fetchingJob(false))
        dispatch(apiError(e))
      })
  }
}

export function requestJobDownload(jobId, downloadName) {
  return (dispatch) => {
    dispatch(updateJobDownload(jobId, downloadName, { status: downloadStatuses.requesting }))
    putStartDownload(jobId, downloadName)
      .then(() => {
        dispatch(updateJobDownload(jobId, downloadName, { status: downloadStatuses.downloading }))
      })
  }
}

export function fetchJobDownloadStatus(jobId, downloadName) {
  return (dispatch) => {
    getJobDownloadStatus(jobId, downloadName)
      .then(({ data }) => {
        dispatch(updateJobDownload(jobId, downloadName, data))
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function fetchJobStatus({ jobId }) {
  return (dispatch, getState) => {
    const isExternal = isExternalFromState(getState())
    getSingleJob(jobId, isExternal)
      .then(({ data: jobData }) => {
        // Get job details (in case we're not coming from the details page)
        const job = decorateJob(jobData)
        const { job: { _isValidatingData } } = getState()

        dispatch(
          updateJob({
            jobId,
            jobStatusDesc: job.jobStatusDesc,
            progressStatus: job.progressStatus,
            step: job.step,
            dataValidation: job.dataValidation,
            tables: job.tables,
            lastModifiedDate: job.lastModifiedDate,
            testsSelected: job.testsSelected,
            testCount: job.testCount,
          }),
        )

        if (_isValidatingData && jobDataIsValidated(job)) {
          dispatch(finishedDataValidation())
          dispatch(push(`/create/${jobId}/dataquality`))
        }
      })
      .catch((e) => {
        dispatch(apiError(e))
      })
  }
}

export const updatedTeamMembers = (userId, members) => ({
  type: JOB_UPDATED_JOB_MEMBERS,
  processedMembers: processJobMembers(userId, members),
})

export function updateTeamMembers(jobId, members) {
  return (dispatch, getState) => {
    postJobMembers(jobId, members)
      .then(({ data }) => {
        const { app: { user: { id: userId } } } = getState()
        dispatch(updatedTeamMembers(userId, data))
        dispatch(notify('Updated team members successfully'))
      })
      .catch(() => {
        dispatch(notify('Failed to save members. Please try again', 'error'))
      })
  }
}

export function updateMembers(formId, data) {
  return (dispatch, getState) => {
    dispatch(formSubmit(formId))
    data.userName = data.members.map(member => member.value).filter(val => val !== null)

    postJobMembers(data.jobId, data)
      .then(({ data: updatedData }) => {
        const { app: { user: { id: userId } } } = getState()
        dispatch(updatedTeamMembers(userId, updatedData))
        dispatch(formSubmitComplete(formId))

        setTimeout(() => {
          dispatch(formSubmitClear(formId))
          dispatch(modalHide(constants.MODAL_MANAGE_JOB))
          dispatch(formDestroy(formId))
          dispatch(notify('Updated team members successfully'))
        }, 1000)
      })
      .catch(() => {
        dispatch(notify('Failed to save members. Please try again', 'error'))
      })
  }
}

export function fetchJobMembers(jobId) {
  return (dispatch, getState) => {
    dispatch(fetchingJobMembers(jobId))
    getJobMembers(jobId, isExternalFromState(getState()))
      .then(({ data }) => {
        const { app: { user: { id: userId } } } = getState()
        dispatch(fetchedJobMembers(jobId, userId, data))
      })
      .catch(() => dispatch(notify('Failed to fetch job members', 'error')))
  }
}

const updateReport = (jobId, analysisId, report) => ({
  type: JOB_UPDATE_JOB_REPORT,
  jobId,
  analysisId,
  report,
})

export function updateDataPrep({ jobId, description }) {
  return (dispatch) => {
    putDataPrepRequest(
      jobId,
      description,
    )
      .then((resp) => {
        dispatch(updateDataPrepAdded(resp.data))
      })
      .catch((error) => {
        dispatch(notify(`Failed to update Data Prep: ${error}`, 'error'))
      })
  }
}

export function launchWorkbench(jobId) {
  return (dispatch) => {
    dispatch(launchingWorkbench())
    dispatch(startedSpinningVisualisation())
    postLaunchWorkbench(jobId)
      .then((resp) => {
        window.open(resp.data)
      })
      .catch((error) => {
        dispatch(notify(`Failed to launch Workbench: ${error}`, 'error'))
      })
      .finally(() => {
        dispatch(launchedWorkbench())
        setTimeout(() => { dispatch(finishedSpinningVisualisation()) }, 2000)
      })
  }
}

export const updatedDataPrepReqStatus = (newStatus) => {
  return (dispatch, getState) => {
    const { job: { dataPreparationRequest } } = getState()
    const updatedDataPrepReq = { ...dataPreparationRequest, status: newStatus }
    dispatch(updateDataPrepAdded(updatedDataPrepReq))
  }
}

export function postDataPreparationEmail(jobId) {
  return (dispatch) => {
    sendDataPrepEmail(jobId)
      .then(() => {
        dispatch(notify('Data services team has been emailed.'))
        dispatch(updatedDataPrepReqStatus('Requested'))
        dispatch(startedSpinningVisualisation())
      })
      .catch(() => {
        dispatch(notify('Failed to notify the Data services team.', 'error'))
      })
  }
}

export function fetchReport({ jobId, analysisId }) {
  return (dispatch, getState) => {
    getRequest(`/job/${jobId}/reports/${analysisId}`)
      .then(({ data: report }) => {
        const { job: { reports } } = getState()
        if (reports === null) {
          dispatch(fetchReports(jobId))
            .then(() => {
              dispatch(updateReport(jobId, analysisId, report))
            })
        } else {
          dispatch(updateReport(jobId, analysisId, report))
        }
      })
      .catch(() => {
        dispatch(fetchingJob(false))
      })
  }
}

export function updateTestParameters(
  { analyses, testParameterList, jobId },
  onSuccess = null,
  key = null,
) {
  return (dispatch, getState) => {
    const isCategorySpecific = key !== null
    dispatch(isCategorySpecific ? settingCategoryTestParameters() : settingTestParameters())
    postParameters(jobId, { testParameterList }, isExternalFromState(getState()))

      .then(({ data }) => {
        if (data === 'Success' || data.respTests === 'Success') {
          dispatch(isCategorySpecific ? finishedSettingCategoryTestParameters() : finishedSettingTestParameters())
          dispatch(updateJob({ analyses }))
          if (onSuccess != null) {
            onSuccess()
            if (isCategorySpecific) {
              dispatch(notify(`Parameters saved for category : ${key}`))
            }
          }
        } else {
          dispatch(isCategorySpecific ? finishedSettingCategoryTestParameters() : finishedSettingTestParameters())
          dispatch(
            notify(
              'Failed to process data. Please select and upload files again', 'error',
            ),
          )
        }
      })
      .catch((e) => {
        dispatch(isCategorySpecific ? finishedSettingCategoryTestParameters() : finishedSettingTestParameters())
        if (e.response.data.code === 409) {
          dispatch(
            notify(
              `Failed to process data. ${e.response.data.result.info.message}`, 'error',
            ),
          )
        } else {
          dispatch(
            notify(
              'Failed to process data. Please select and upload files again', 'error',
            ),
          )
        }
      })
  }
}

export function updateSolutionParameters(data) {
  return (dispatch, getState) => {
    dispatch(sending())
    const { job } = getState()
    const { jobId } = job
    const jobParameterList = []
    const valueItem = (parameterName, parameterValue) => ({
      parameterName,
      parameterValue,
    })

    jobParameterList.push(
      valueItem('Period_Start_Date', moment(data.dpFrom).format('YYYY-MM-DD')),
    )
    jobParameterList.push(
      valueItem('Period_End_Date', moment(data.dpTo).format('YYYY-MM-DD')),
    )
    jobParameterList.push(
      valueItem('Ye_Date', moment(data.dpYE).format('YYYY-MM-DD')),
    )

    postJobParameters(jobId, {
      jobId,
      name: data.name,
      jobParameterList,
    })
      .then(({ data: resp }) => {
        if (resp === 'Success') {
          const { dpFrom, dpTo, dpYE } = data
          dispatch(
            updateJob({
              dpFrom,
              dpTo,
              dpYE,
              name: data.name,
            }),
          )
          dispatch(
            updateJobinList({
              jobId,
              dpFrom,
              dpTo,
              dpYE,
              name: data.name,
            }),
          )
          dispatch(saved())
          setTimeout(() => {
            dispatch(resetCTA())
            const { job: { isCustom } } = getState()
            dispatch(push(`/create/${jobId}/${isCustom ? 'getdata' : 'selecttests'}`))
          }, 1000)
        }
      })
      .catch(() => {
        dispatch(saved())
        dispatch(resetCTA())
      })
  }
}

export function updatePackageParameters(finalParams, onSuccess, isExternal) {
  return (dispatch) => {
    dispatch(savingSolutionParam())
    postJobPackageParameters(finalParams.jobId, finalParams, isExternal)
      .then(() => {
        dispatch(solutionParamUpdated())
        dispatch(resetCTA())
        dispatch(
          notify(
            'Solution-wide parameters saved.',
          ),
        )
      })
      .catch((e) => {
        dispatch(
          notify(
            `Failed to process data. ${e.response.data.result.info.message}`, 'error',
          ),
        )
        dispatch(resetCTA())
      })
      .finally(() => {
        onSuccess()
      })
  }
}

export const SavePackageParameters = (onSuccess, updatedSolutionParams, isExternal) => {
  return (dispatch, getState) => {
    const { job } = getState()
    const formatValue = (value, type) => {
      if (isBoolean(value)) {
        return value ? 1 : 0
      }
      if (type === 'TABLE') {
        return JSON.stringify(value)
      }
      if (value.value) {
        return value.value
      }
      return value
    }

    const JobParameterList = updatedSolutionParams.map(param => ({
      parameterName: param.id,
      parameterValue: formatValue(param.updatedValue, param.type),
    }))

    const finalParams = {
      Name: job.name,
      JobParameterList,
      jobId: job.jobId,
    }

    dispatch(
      updatePackageParameters(
        finalParams,
        onSuccess,
        isExternal,
      ),
    )
  }
}

export const SaveParameters = (onSuccess, updatedAnalysesParameters = null, key = null, analysis = null) => {
  return (dispatch, getState) => {
    const { job } = getState()
    const { job: { jobId, analyses, packageParameters }, forms } = getState()
    const FORM_ID = constants.FORM_SETUP
    const currentDisplayValues = getCurrentDisplayValues(analyses, packageParameters)
    const reqfields = requiredFields(
      schemaWithTests(job, key, analysis).formFields,
    ).filter(rf => rf.meta.parameter.display
      && !isConditionallyDisabledFromParameterDisplay(rf.meta.parameter, currentDisplayValues))
    const form = forms[FORM_ID]

    // Won't have a form if there are no parameters
    if (!form) {
      onSuccess()
      return
    }

    const errors = validateAll(reqfields, form)

    if (errors.length > 0) {
      handleErrors(errors).forEach(errorState => dispatch(formUpdate(FORM_ID, errorState)))
      dispatch(resetCTA())
    } else {
      const data = getSerializedFormState(form)
      const parameterList = []
      const formatValue = (value, type) => {
        if (isBoolean(value)) {
          return value ? 1 : 0
        }
        if (type === 'TABLE') {
          return JSON.stringify(value)
        }
        if (value.value) {
          return value.value
        }
        return value
      }

      let analysesToPost

      if (key !== null) {
        analysesToPost = analyses.map(a => ({
          ...a,
          parameters: a.parameters?.map(ap => ({ ...ap, value: data[`${ap.id}`] })),
        }))
      } else {
        packageParameters
          .filter(pp => pp.display && !isConditionallyDisabledFromParameterDisplay(pp, currentDisplayValues))
          .forEach((parameter) => {
            parameterList.push({
              testName: null,
              name: parameter.variableName,
              value: formatValue(data[`${parameter.id}`], parameter.type),
              category: null,
            })
          })

        analysesToPost = analyses.slice()
      }

      if (updatedAnalysesParameters === null) {
        analyses.forEach((test) => {
          if (test.parameters) {
            test.parameters
              .filter(ap => ap.display && !isConditionallyDisabledFromParameterDisplay(ap, currentDisplayValues))
              .forEach((parameter) => {
                parameterList.push({
                  testName: test.id,
                  name: parameter.variableName,
                  value: formatValue(data[`${parameter.id}`], parameter.type),
                  category: parameter.category,
                })
              })
          }
        })
      } else {
        updatedAnalysesParameters.map(up => parameterList.push({
          testName: up.analysisId,
          name: up.id,
          value: formatValue(up.newValue, up.type),
          category: up.category,
        }))
      }

      if (parameterList.length > 0) {
        dispatch(
          updateTestParameters(
            {
              analyses: analysesToPost,
              testParameterList: parameterList,
              jobId,
            },
            onSuccess,
            key,
          ),
        )
      } else {
        onSuccess()
      }
    }
  }
}

export const cloneParameters = (onSuccess) => {
  return (dispatch, getState) => {
    const { job: { jobId, analyses, packageParameters } } = getState()
    const parameterList = []
    const formatValue = (value, type) => {
      if (isBoolean(value)) {
        return value ? 1 : 0
      }
      if (type === 'TABLE') {
        return JSON.stringify(value)
      }
      if (value.value) {
        return value.value
      }
      return value
    }

    packageParameters
      .forEach((parameter) => {
        parameterList.push({
          testName: null,
          name: parameter.variableName,
          value: formatValue(parameter.value, parameter.type),
        })
      })

    analyses.forEach((test) => {
      if (test.parameters) {
        test.parameters
          .forEach((parameter) => {
            parameterList.push({
              testName: test.id,
              name: parameter.variableName,
              value: formatValue(parameter.value, parameter.type),
            })
          })
      }
    })

    if (parameterList.length > 0) {
      dispatch(
        updateTestParameters(
          {
            analyses: analyses.slice(),
            testParameterList: parameterList,
            jobId,
          },
          onSuccess,
        ),
      )
    } else {
      onSuccess()
    }
  }
}

const saveAndResetJobCTA = () => {
  return (dispatch) => {
    dispatch(saved())
    dispatch(resetCTA())
  }
}

export function submitTests({ jobId, testList, isCloned = false }) {
  return (dispatch, getState) => {
    const postBody = {
      testList: testList.map(t => ({ id: t.name, selected: t.selected })),
    }
    postAnalyses(jobId, postBody)
      .then(({ data: resp }) => {
        if (resp === 'Success') {
          Promise.all([
            getParameters({ jobId }),
            getJobDataRecipes(jobId),

          ])
            .then(
              (
                [
                  { package: packageParameters, test: parametersGroup },
                  { data: recipes },
                ],
              ) => {
                let packageParametersToUse = packageParameters
                if (isCloned) {
                  const { clonedJobDetails } = getState()
                  clonedJobDetails.analyses.map((a) => {
                    parametersGroup[a.id] = a.parameters
                    return (
                      parametersGroup
                    )
                  })
                  if (clonedJobDetails.packageParameters.length > 0) {
                    packageParametersToUse = clonedJobDetails.packageParameters
                  }
                }

                const analyses = testList
                  .map(t => ({
                    ...t,
                    id: t.name,
                    name: t.displayName,
                    parameters: parametersGroup[t.name.toLowerCase()],
                  }))

                dispatch(updateJob({
                  analyses, packageParameters: packageParametersToUse, recipes, step: 3, jobStatusDesc: 'NoData',
                }))
                dispatch(
                  updateJobinList({
                    jobId,
                    testCount: analyses.filter(test => test.selected).length,
                    analyses,
                    recipes,
                    step: 3,
                  }),
                )
                dispatch(saved())
                setTimeout(() => {
                  dispatch(resetCTA())
                  const { job } = getState()
                  const { isCalculated } = job
                  const isControlsAdvantage = job.packageName.toLowerCase() === 'ControlsAdvantage'.toLowerCase()
                  const { url } = getPostAnalysisSelectionLink(jobId, isCalculated, isControlsAdvantage)
                  dispatch(push(url))
                }, 1000)
              },
            )
            .finally(() => {
              if (isCloned) {
                dispatch(cloneParameters(() => dispatch(saveAndResetJobCTA())))
              }
            })
        }
      })
      .catch((error) => {
        const dataFromError = error.response && error.response.data
        if (
          dataFromError
          && dataFromError.info
          && dataFromError.info.parameters
        ) {
          const deselectError = dataFromError.info.parameters.some(
            parameter => parameter.message.indexOf('could not be deselected.') > -1,
          )
          if (deselectError) {
            dispatch(
              notify(
                'You cannot remove analyses once your job has been processed', 'error',
              ),
            )
          }
        }
        dispatch(saved())
        dispatch(resetCTA())
      })

    dispatch(sending())
  }
}

export function cloneJob() {
  return (dispatch, getState) => {
    const { packages, job: { jobId }, clonedJobDetails: { analyses, packageId } } = getState()
    const tests = packages.packages[packageId].analyses

    dispatch(submitTests({
      jobId,
      testList: tests.map((test) => {
        return {
          ...test,
          selected: analyses.map(a => a.id).indexOf(test.name) > -1,
          hasData: false,
        }
      }),
      isCloned: true,
    }))
  }
}

export function createJob(data, iscloned = false) {
  return (dispatch, getState) => {
    dispatch(sending())
    data.engagementCode = typeof data.engagementCode === 'object'
      ? data.engagementCode.value
      : data.engagementCode
    const jobParameterList = []
    const valueItem = (parameterName, parameterValue) => ({
      parameterName,
      parameterValue,
    })
    jobParameterList.push(
      valueItem('Period_Start_Date', moment(data.dpFrom).format('YYYY-MM-DD')),
    )
    jobParameterList.push(
      valueItem('Period_End_Date', moment(data.dpTo).format('YYYY-MM-DD')),
    )
    jobParameterList.push(
      valueItem('Ye_Date', moment(data.dpYE).format('YYYY-MM-DD')),
    )

    const { app: { user: { id: userId } } } = getState()

    postJob({
      name: data.name,
      packageName: data.packageValue,
      engagementCode: data.engagementCode,
      benchmarkAllowed: data.benchmarkingEnabled === 'Yes',
      jobParameterList,
      customEngagementCode: data.customEngagementCode,
      customClientCode: data.customClientCode,
      isClientJob: data.isClientJob && data.isClientJob === 'Yes',
      executionLimit: data.executionLimit ? data.executionLimit : -1,
      expiryDate: data.jobExpiryDate,
    })
      .then((resp) => {
        data.jobId = resp.data.jobId
        data._isCreateJobError = false
        if (iscloned) {
          dispatch(updateClonedJobNewJobId(data.jobId))
        }
        dispatch(updateJob({
          ...data,
          ...processJobMembers(userId, resp.data.members),
        }))
        setTimeout(() => {
          if (iscloned) {
            dispatch(cloneJob())
          } else {
            dispatch(saved())
            dispatch(resetCTA())
            const { packages: { packages } } = getState()
            const isCustom = packages[data.packageValue].analyses.length === 0 && packages[data.packageValue].inputTables.length === 0
            dispatch(push(`/create/${data.jobId}/${isCustom ? 'getdata' : 'selecttests'}`))
          }
          dispatch(notify(`'${data.name}' has been created. You can now exit this flow and your position will be saved.`))
        }, 1000)
      })
      .catch((error) => {
        const FORM_ID = constants.FORM_CREATE_JOB
        const info = (error.response && error.response.data && error.response.data.info)
          || {}
        if (info.parameters) {
          info.parameters.forEach((parameter) => {
            const fieldParts = parameter.name.split('.')
            const field = fieldParts.length > 1 ? fieldParts[1] : fieldParts[0]
            const errorObj = {
              valid: false,
            }
            let reduxField = ''
            switch (field) {
              case 'EngagementCode':
                errorObj.error = 'Enter a valid engagement code or name'
                reduxField = 'engagementCode'
                break
              case 'JobName':
                errorObj.error = 'Enter a valid job name'
                reduxField = 'name'
                break
              default:
                break
            }

            dispatch(formUpdate(FORM_ID, handleError(errorObj, reduxField)))
          })
        }

        if (info.message) {
          dispatch(notify(`Could not create job: ${info.message}`, 'error'))
        }

        dispatch(resetCTA())
      })
  }
}

export function updateJobRecipe(recipeName) {
  return (dispatch, getState) => {
    const { job: { jobId, recipeName: oldRecipeName } } = getState()

    dispatch(sending())
    dispatch(
      updateJob({
        recipeName,
      }),
    )
    dispatch(
      updateJobinList({
        jobId,
        recipeName,
      }),
    )

    patchUpdateJob(jobId, { recipeName })
      .then(() => {
        dispatch(saved())
      })
      .catch(() => {
        dispatch(notify('Your job recipe could not be saved, please contact support.', 'error'))

        dispatch(
          updateJob({
            recipeName: oldRecipeName,
          }),
        )
        dispatch(
          updateJobinList({
            jobId,
            recipeName: oldRecipeName,
          }),
        )
        dispatch(saved())
      })
  }
}

export function updateJobName({ name }) {
  return (dispatch, getState) => {
    dispatch(sending())
    const { job: { jobId } } = getState()

    const isExternal = isExternalFromState(getState())

    const sanitisedJobName = sanitizeInput(name, true)

    const onSuccess = () => {
      dispatch(
        updateJob({
          name: sanitisedJobName,
        }),
      )
      dispatch(
        updateJobinList({
          jobId,
          name: sanitisedJobName,
        }),
      )
      dispatch(saved())
    }

    const onError = () => {
      const { WORDMARK } = getConfig()
      dispatch(notify(`Your job name could not be saved, please contact the ${WORDMARK} team.`))
    }

    if (isExternal) {
      patchUpdateJobName(jobId, sanitisedJobName, true).then(onSuccess).catch(onError)
    } else {
      patchUpdateJob(jobId, {
        name: sanitisedJobName.trim(),
      }).then(onSuccess).catch(onError)
    }
  }
}

export function reActivateJob({ executionLimit, expiryDate }) {
  return (dispatch, getState) => {
    dispatch(sending())
    const { job: { jobId } } = getState()

    const isExternal = isExternalFromState(getState())

    const onSuccess = ({ data: job }) => {
      dispatch(
        updateJob({
          executionLimit: job.executionLimit,
          expiryDate: makeDate(job.expiryDate),
          canExecute: job.canExecute,
          hasExpired: job.hasExpired,
        }),
      )
      dispatch(saved())
      dispatch(notify('Job re-activated.'))
    }

    const onError = () => {
      const { WORDMARK } = getConfig()
      dispatch(notify(`Your job could not be re-activated, please contact the ${WORDMARK} team`))
    }

    if (!isExternal) {
      patchUpdateJob(jobId, {
        executionLimit, expiryDate,
      }).then(onSuccess).catch(onError)
    }
  }
}

export function updateJobParameters(data) {
  return (dispatch, getState) => {
    dispatch(sending())
    const { job } = getState()
    const { jobId } = job
    const jobParameterList = []
    const valueItem = (parameterName, parameterValue) => ({
      parameterName,
      parameterValue,
    })
    jobParameterList.push(
      valueItem('Period_Start_Date', moment(data.dpFrom).format('YYYY-MM-DD')),
    )
    jobParameterList.push(
      valueItem('Period_End_Date', moment(data.dpTo).format('YYYY-MM-DD')),
    )
    jobParameterList.push(
      valueItem('Ye_Date', moment(data.dpYE).format('YYYY-MM-DD')),
    )

    postJobParameters(jobId, {
      jobId,
      name: data.name,
      jobParameterList,
    })
      .then(() => {
        const { dpFrom, dpTo, dpYE } = data
        dispatch(
          updateJob({
            dpFrom,
            dpTo,
            dpYE,
            name: data.name,
          }),
        )
        dispatch(
          updateJobinList({
            jobId,
            dpFrom,
            dpTo,
            dpYE,
            name: data.name,
          }),
        )
        dispatch(saved())
        dispatch(notify('Updated job parameters successfully'))

        setTimeout(() => {
          dispatch(resetCTA())
          const { job: { isCustom } } = getState()
          dispatch(push(`/create/${jobId}/${isCustom ? 'getdata' : 'selecttests'}`))
        }, 1000)
      })
      .catch(() => {
        dispatch(saved())
        dispatch(resetCTA())
        dispatch(notify('Failed to update job parameters'))
      })
  }
}

export const fetchDataRequirements = (jobId) => {
  return (dispatch, getState) => {
    const isExternal = isExternalFromState(getState())
    dispatch(requestDataRequirements())
    const { recipe } = getState().job
    getJobRequiredTables({
      jobId, includeColumns: true, recipe, isExternal,
    })
      .then(({ data: files }) => {
        initUploaders(jobId, files).then((uploaders) => {
          dispatch(updateJob({ jobId, files }))
          dispatch(updateUploaders(uploaders))
          dispatch(receivedDataRequirements())
        })
      })
      .catch(() => {
        dispatch(notify('Could not load data requirements, please contact support for assistance.'))
        dispatch(receivedDataRequirements())
      })
  }
}

export function fetchTargetSchemas() {
  return (dispatch, getState) => {
    const { job: { packageId, availableTargetSchemas } } = getState()
    getTargetSchemas(packageId)
      .then(({ data }) => {
        dispatch(fetchedTargetSchemas(Array.from(new Set(data.concat(availableTargetSchemas)))))
      })
      .catch(() => {
        dispatch(notify('Failed to load avaliable target schemas'))
      })
  }
}

export function removeDataPrepRequest(jobId, isDataReady) {
  return (dispatch) => {
    deleteDataPrepRequest(jobId)
      .then(() => {
        dispatch(
          updateJob({
            isDataReady,
            dataPreparationRequest: null,
          }),
        )
      })
      .catch(() => {
        dispatch(notify('Failed to delete data preparation request'))
      })
  }
}

export function uploadFileToFileGroup(fileGroup, file) {
  return (dispatch, getState) => {
    const { job: { fileGroups } } = getState()
    postFileToFileGroup(fileGroup.id, file)
      .then((resp) => {
        fileGroup.files = fileGroup.files.map((f) => {
          return f.name === file.name ? resp.data : f
        })
        const updatedFileGroups = fileGroups.filter(fg => fg.id !== fileGroup.id).concat(fileGroup)
        dispatch(updateFileGroups(updatedFileGroups))
      })
      .catch(() => {
        dispatch(notify(`Failed to add file: ${file.name} to file group: ${fileGroup.name}`))
      })
  }
}

export function removeFileGroup(fileGroupName, fileGroupId) {
  return (dispatch, getState) => {
    deleteFileGroup(fileGroupId)
      .then(() => {
        const { job: { fileGroups } } = getState()
        const updatedFileGroups = fileGroups.filter(fg => fg.id !== fileGroupId)
        dispatch(updatedDataPrepReqStatus('Modified'))
        dispatch(
          dispatch(updateFileGroups(updatedFileGroups)),
        )
      })
      .catch(() => {
        dispatch(notify(`Failed to delete file group:${fileGroupName}`))
      })
  }
}

export function addFileGroup(dataPreparationRequestId, description, name, targetSchemaList) {
  return (dispatch, getState) => {
    const targetSchemas = targetSchemaList.map((targetSchema) => {
      return { targetSchema }
    })
    postFileGroup(dataPreparationRequestId, { description, name, targetSchemas })
      .then((resp) => {
        const { job: { fileGroups } } = getState()
        dispatch(updateFileGroups([...fileGroups, {
          name, targetSchemas: targetSchemaList, description, files: resp.data.files === null ? [] : resp.data.files, id: resp.data.id,
        }]))
      })
      .catch((e) => {
        dispatch(notify('Failed to create the file group.', e))
      })
  }
}

export function editFileGroup(fileGroupId, description, name, targetSchemaList) {
  return (dispatch, getState) => {
    const { job: { fileGroups } } = getState()
    const fileGroupToUpdateName = fileGroups.filter(fg => fg.id === fileGroupId).map(ffg => ffg.name)
    const targetSchemas = targetSchemaList.map((targetSchema) => {
      return { targetSchema }
    })
    updateFileGroup(fileGroupId, { description, name, targetSchemas })
      .then((resp) => {
        const updatedFileGroups = fileGroups.filter(fg => fg.id !== fileGroupId).concat({
          name, targetSchemas: targetSchemaList, description, files: resp.data.files, id: resp.data.id,
        })
        dispatch(updateFileGroups(updatedFileGroups))
      })
      .catch((e) => {
        dispatch(notify(`Failed to update the file group: ${fileGroupToUpdateName}`, e))
      })
  }
}

export function fetchAllFileGroups(dataPreparationRequestId) {
  return (dispatch) => {
    getAllFileGroups(dataPreparationRequestId)
      .then(({ data }) => {
        const fileGroups = data.map((fg) => {
          return { ...fg, targetSchemas: fg.targetSchemas.map(ts => ts.targetSchema) }
        })
        dispatch(updateFileGroups(fileGroups))
      })
      .catch((e) => {
        dispatch(notify('Failed to load the file group.', e))
      })
  }
}

export function goToInputdata({ jobId }) {
  return (dispatch, getState) => {
    const { _isLoadingDataRequirements } = getState().job

    // Ignore multiple calls to this action
    if (_isLoadingDataRequirements) {
      return
    }
    // TODO untangle this
    dispatch(push(`/create/${jobId}/inputdata`))
  }
}

export function upload(uploaderUIs) {
  return (dispatch) => {
    dispatch(sending())
    dispatch(updateStatus(constants.UPLOAD_STATUS.UPLOADING))

    if (uploaderUIs.length === 0) {
      return
    }

    forEach(uploaderUIs, (uploaderUI) => {
      if (uploaderUI) {
        uploaderUI.methods.uploadStoredFiles()
      }
    })
  }
}

export function executeJob(jobId) {
  return (dispatch, getState) => {
    dispatch(requestJobExecution())
    const isExternal = isExternalFromState(getState())
    const { job: { packageId } } = getState()
    putStartExecution(jobId, isExternal)
      .then(() => {
        dispatch(saved())
        dispatch(progressModel(true))
        setTimeout(() => {
          dispatch(resetCTA())
          dispatch(push(
            isExternal
              ? `/insights/${packageId}`
              : '/jobs/engagements',
          ))
        }, 1000)
      })
  }
}

export function submit(data) {
  return (dispatch, getState) => {
    dispatch(updateStatus(constants.UPLOAD_STATUS.NORMAL))

    const { uploaders: stateUploaders } = getState()

    const fileList = map(stateUploaders.uploaders, uploader => ({
      tableCode: uploader.id,
      files: filter(uploader.files, file => file.status === 'uploaded').map(
        ({ name, delimiter, tableHeaders }) => ({
          name,
          delimiter,
          dataRecipe: uploader.dataRecipe,
          mapping: tableHeaders
            .filter(tableHeader => tableHeader.value !== '(not mapped)')
            .map(({ header, value, dateFormat }) => ({
              header,
              value,
              dateFormat,
            })),
        }),
      ),
    }))

    const isExternal = isExternalFromState(getState())

    postCommitData(data.jobId, fileList.filter(x => x.files.length > 0), isExternal)
      .then(() => {
        dispatch(endUploadingFiles())
      })
      .catch((error) => {
        dispatch(saved())
        setTimeout(() => {
          dispatch(resetCTA())
        }, 1000)

        const responseInfo = error.response && error.response.data && error.response.data.info
        if (
          responseInfo
          && responseInfo.parameters
          && responseInfo.parameters.length > 0
        ) {
          // Highlight particular problem files
          const { parameters } = responseInfo
          const errorDetails = extractResponseParameters(parameters)

          // Apply any file upload errors
          const { uploaders } = getState().uploaders
          errorDetails.filter(x => x.name === 'tables').forEach((errorDetail) => {
            const uploader = uploaders[
              Object.keys(uploaders).filter(k => some(uploaders[k].files, (x => x.status === 'uploaded')))[errorDetail.index]
            ]
            if (errorDetail.inner && errorDetail.inner.name === 'Files') {
              const file = uploader.files[
                Object.keys(uploader.files)[errorDetail.inner.index]
              ]
              dispatch(
                addFileError(
                  {
                    error: errorDetail.inner.message,
                    status: 'error',
                    name: file.name,
                  },
                  uploader.id,
                ),
              )

              // Delete all files in table with error
              forEach(uploader.files, ((f) => {
                dispatch(
                  deleteFile(
                    {
                      fileId: f.fileId,
                    },
                    uploader.id,
                  ),
                )
              }))
            }
          })
        }

        // Files without errors should be treated as uploaded as they're now in the DB
        const nonErrorUploaders = filter(
          getState().uploaders.uploaders,
          ({ errors, files }) => errors.length === 0 && some(files, f => f.status === 'uploaded'),
        )
        forEach(nonErrorUploaders, ({ id, files }) => {
          forEach(
            files,
            ({ fileId, status }) => {
              // Saved files have now been replaced
              if (status === 'saved') {
                dispatch(deleteFile({ fileId }, id))
              }

              // Uploaded files have now been saved
              if (status === 'uploaded') {
                dispatch(updateUploaderFileStatus(id, fileId, 'saved'))
              }
            },
          )
        })

        dispatch(
          notify(
            'Failed to process data. Please select and upload files again',
          ),
        )
      })
  }
}

export function verify() {
  return (dispatch, getState) => {
    dispatch(sending())
    setTimeout(() => {
      dispatch(saved())
    }, 1000)
    setTimeout(() => {
      dispatch(resetCTA())
      dispatch(push(`/create/${getState().jobId}/finalise`))
    }, 2000)
  }
}

export function finalise() {
  return (dispatch) => {
    dispatch(sending())
    setTimeout(() => {
      dispatch(saved())
    }, 1000)
    setTimeout(() => {
      dispatch(resetCTA())
      dispatch(push('/jobs/finalise'))
    }, 2000)
  }
}

export const updateFileStatus = (index, file) => ({
  type: JOB_FILE_UPDATE,
  index,
  file,
})

export function checkTimeframe() {
  return (dispatch, getState) => {
    const form = getState().forms[constants.FORM_CREATE_JOB]
    const messages = []
    if (form.dpFrom.value && moment(form.dpFrom.value).isAfter(moment())) {
      messages.push({
        type: 'warn',
        text: 'The analysis start date is in the future.',
      })
    }

    if (form.dpFrom.value && form.dpTo.value) {
      if (
        moment(form.dpTo.value)
          .subtract(1, 'months')
          .isBefore(moment(form.dpFrom.value))
      ) {
        messages.push({
          type: 'warn',
          text:
            'You have defined a period of analysis less than the recommended timeframe of one month.',
        })
      }

      if (
        moment(form.dpTo.value)
          .subtract(3, 'years')
          .isAfter(moment(form.dpFrom.value))
      ) {
        messages.push({
          type: 'warn',
          text: 'The from date selected is more than 3 years old.',
        })
      }
    }

    if (
      (!moment(form.dpFrom.value).isValid() && form.dpFrom.value)
      || (!moment(form.dpYE.value).isValid() && form.dpYE.value)
      || (!moment(form.dpTo.value).isValid() && form.dpTo.value)
    ) {
      messages.push({
        type: 'error',
        text:
          'Please ensure dates are correctly formatted.',
      })
    }

    if (
      form.dpFrom.value
      && form.dpTo.value
      && moment(form.dpFrom.value).isAfter(moment(form.dpTo.value))
    ) {
      messages.push({
        type: 'error',
        text:
          'The analysis start date is after the analysis end date. Please select a valid analysis period.',
      })
    }

    if (
      form.dpFrom.value
      && form.dpYE.value
      && moment(form.dpFrom.value).isAfter(moment(form.dpYE.value))
    ) {
      messages.push({
        type: 'error',
        text:
          'The year end date is before the analysis start date. Please select a year end date within the period of analysis.',
      })
    }

    if (
      form.dpYE.value
      && ['30 Jun', '31 Dec', '30 Sep', '31 Mar'].indexOf(
        moment(form.dpYE.value).format('DD MMM'),
      ) < 0
    ) {
      messages.push({
        type: 'warn',
        text:
          'You have selected a year end date that is not typically expected (30 Jun, 31 Dec, 30 Sep or 31 Mar).',
      })
    }

    if (
      form.jobExpiryDate?.value
      && moment(form.jobExpiryDate.value).isBefore(moment(new Date()))
    ) {
      messages.push({
        type: 'error',
        text:
          'The job expiry date is before today\'s date. Please select a valid job expiry date.',
      })
    }

    dispatch(
      updateJob({
        setupErrors: messages,
      }),
    )
  }
}

export function getMemberByQuery(query, onSuccess) {
  return () => {
    getRequest(`/user/suggestusers?search=${query}`).then(({ data }) => {
      onSuccess(
        optionsFormat(
          data.map(user => ({
            name: `${user.firstName} ${user.surname}`,
            id: user.username,
            email: user.email,
          })),
        ),
      )
    })
  }
}

export function searchMembersByQuery(val, callback) {
  return () => {
    getRequest(`/user/suggestusers?search=${val}`)
      .then(({ data }) => {
        callback(data)
      })
  }
}

export function removeMember(jobId, userId, userDisplayName, callback) {
  return (dispatch, getState) => {
    deleteRequest(`job/${jobId}/user/${userId}`)
      .then(() => {
        const { job } = getState()
        dispatch(removeJobMember(jobId, userId))
        dispatch(notify(`You have removed ${userDisplayName} from '${job.name}'.`))
        callback()
      })
      .catch(() => {
        dispatch(notify(`Failed to remove ${userDisplayName}. Please try again later.`))
      })
  }
}

export function sendEmail({ jobId }) {
  return (dispatch) => {
    postRequest('/file/getreport', { jobId })
      .then(({ data }) => {
        if (data === 'Success') {
          dispatch(notify('Email sent'))
        } else {
          dispatch(notify('Failed to send. Please try again'))
        }
      })
      .catch(() => {
        dispatch(notify('Failed to send. Please try again'))
      })
  }
}

export function setBenchmarkFlag({ jobId, benchmarkAllowed }) {
  return (dispatch) => {
    postBenchmarkFlag(jobId, benchmarkAllowed)
      .then(() => {
        dispatch(
          updateJob({
            benchmarkingEnabled: benchmarkAllowed ? 'Yes' : 'No',
          }),
        )
        dispatch(notify('Your benchmarking selection updated'))
      })
      .catch(() => {
        dispatch(notify('Failed to update. Please try again'))
      })
  }
}

export function republishReports(jobId) {
  return (dispatch) => {
    postRequest('/job/publishreports', { jobId })
      .then(() => {
        dispatch(republishedReports())
      })
      .catch(() => {
        dispatch(
          notify('Failed to re-publish your reports. Please try again later.'),
        )
      })
  }
}

export const dataDownloadStart = () => ({
  type: JOB_DATA_DOWNLOAD_START,
})

export const dataDownloadComplete = () => ({
  type: JOB_DATA_DOWNLOAD_COMPLETE,
})

export function getDataDownload({ jobId }) {
  const { SANDBOX } = getConfig()
  const downloadUrl = `/job/getdatadownload${SANDBOX ? '?demo=true' : ''}`

  return (dispatch) => {
    dispatch(dataDownloadStart())
    dispatch(notify('Preparing your download...', 'accept', 1000000))

    postRequestBinaryFile(downloadUrl, { jobId })
      .then(({ data, filename }) => {
        FileDownload(data, filename)
        dispatch(dataDownloadComplete())
        dispatch(clearNotification())
        dispatch(notify('Download complete'))
      })
      .catch(() => {
        const { WORDMARK } = getConfig()
        dispatch(clearNotification())
        dispatch(
          notify(
            `Could not download your journal entries. Please contact ${WORDMARK} support.`,
          ),
        )
        dispatch(dataDownloadComplete())
      })
  }
}

export function getSampleDataJson(jobId, recipe = '') {
  return (dispatch, getState) => {
    dispatch(fetchingSampleDataJson())

    getJobSampleDataJson({
      jobId,
      recipe,
      isExternal: isExternalFromState(getState()),
    })
      .then(data => (
        dispatch(fetchedSampleDataJson(data))
      ))
      .catch(e => (
        dispatch(fetchedSampleDataJson(e))
      ))
  }
}

export function getSampleDataDownload({ jobId }) {
  return (dispatch, getState) => {
    dispatch(dataDownloadStart())
    dispatch(notify('Preparing your download...', 'accept', 1000000))

    getJobSampleDataZip({ jobId, isExternal: isExternalFromState(getState()) })
      .then(({ data, filename }) => {
        FileDownload(data, filename)
        dispatch(dataDownloadComplete())
        dispatch(clearNotification())
        dispatch(notify('Download complete'))
      })
      .catch(() => {
        const { WORDMARK } = getConfig()
        dispatch(clearNotification())
        dispatch(
          notify(
            `Could not download sample data for this job. Please contact ${WORDMARK} support.`,
          ),
        )
        dispatch(dataDownloadComplete())
      })
  }
}

export const modifyingUserRole = () => ({
  type: JOB_MODIFYING_USER_ROLE,
})

export const modifiedUserRole = (success, userId, roleId) => ({
  type: JOB_MODIFIED_USER_ROLE,
  success,
  userId,
  roleId,
})

export const modifyUserJobrole = (jobId, userId, roleId) => {
  return (dispatch) => {
    dispatch(modifyingUserRole())
    putRequest(`/job/${jobId}/user/${userId}/role/${roleId}`)
      .then(() => {
        dispatch(notify('User role updated successfully'))
        dispatch(modifiedUserRole(true, userId, roleId))
        dispatch(modalHide(constants.MODAL_MODIFY_USER_JOB_ROLE))
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          dispatch(notify('Unable to assign - role is not valid for this user'))
        } else {
          dispatch(notify('Error when assigning role - please refresh and try again'))
        }
        dispatch(modifiedUserRole(false, userId, roleId))
        dispatch(modalHide(constants.MODAL_MODIFY_USER_JOB_ROLE))
      })
  }
}

export const fetchSupportingData = (jobId, isExternal, actionSetId, taskId) => {
  return (dispatch) => {
    getAllSupportingFiles(jobId, isExternal)
      .then(({ data }) => {
        if (data) {
          const filteredFiles = data.filter((file) => {
            if (actionSetId && taskId) {
              return file.tableName.startsWith(`${actionSetId}/${taskId}/`)
            }
            return !file.tableName.includes('/')
          })

          const supportFiles = filteredFiles.map(file => ({
            name: file.filename,
          }))

          const supportFilesStatus = filteredFiles.map(file => ({
            status: 'complete',
            tablename: file.tableName,
            size: file.fileSize,
            name: file.filename,
            date: file.updatedDate,
            id: file.id,
          }))

          dispatch(updateSupportingFiles(supportFiles))
          dispatch(updateSupportingFilesStatus(supportFilesStatus))
        }
      })
      .catch((e) => {
        console.error('Failed to load supporting files:', e)
      })
  }
}

export const fetchAssignableExternalRoles = (jobId) => {
  return (dispatch) => {
    getJobAssignableExternalRoles(jobId)
      .then(({ data }) => {
        dispatch(fetchedExternalRoles(data))
      })
  }
}
