import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalHide } from 'actions/modals'
import { MODAL_IMPORT_ACTIONS } from 'constants/forms'
import { Modal } from '@material-ui/core'
import { Button, Tabs } from '@deloitte/gel-library'
import classNames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import { fetchConnectedJobs, importActions, updateJob } from 'actions/job'
import PropTypes from 'prop-types'
import TabPanel from 'components/TabPanel'
import Spinner from 'components/Spinner'
import PreviousActions from 'components/PreviousActions'
import ConnectedJobActions from 'components/ConnectedJobActions'
import { hot } from 'react-hot-loader/root'
import { getNoOfEntitiesToOverride } from 'actionHub/utils/actionHubApi'
import useImportActions from 'actionHub/hooks/useImportActions'
import styles from './ImportActionsModal.scss'

const propTypes = {
  action: PropTypes.object.isRequired,
}

const ImportActionsModal = ({ action }) => {
  const dispatch = useDispatch()
  const { modals, job, actionHub } = useSelector(state => state)
  const { previousActions, previousEntityMetas } = job.actions
  const show = MODAL_IMPORT_ACTIONS in modals && modals[MODAL_IMPORT_ACTIONS].show
  const { _isImportingAction } = actionHub
  const { updateActionIds } = useImportActions()

  const [isFetchingEntities, setIsFetchingEntities] = useState(false)
  const [isFetchedEntities, setIsFetchedEntities] = useState(false)
  const [tabValue, setTabValue] = React.useState(0)
  const [selectedValue, setSelectedValue] = useState(null)
  const [overridingEntitiesCount, setOverridingEntitiesCount] = useState(null)
  const { actionName, entityMetaId } = action.actionSet

  const effectiveActions = (previousEntityMetas && previousEntityMetas.length > 0)
    ? previousEntityMetas
      .filter(pem => pem.entityMeta?.id === entityMetaId)
      .flatMap(pem => pem.actions)
      .filter(pemActions => pemActions?.actionSet?.actionName === actionName)
    : previousActions.filter(pva => ((action.actionSet.analysisName !== null)
      ? pva.actionSet.analysisName === action.actionSet.analysisName
      : pva.actionSet.actionName === action.actionSet.actionName))

  const fetchData = useCallback(() => {
    if (action.actionSet.analysisName) {
      dispatch(fetchConnectedJobs(job.jobId, action.actionSet.analysisName, 'analysis'))
    } else {
      dispatch(fetchConnectedJobs(job.jobId, action.actionSet.actionName, 'action'))
    }
  }, [action.actionSet.actionName, action.actionSet.analysisName, dispatch, job.jobId])

  useEffect(() => {
    if (show) {
      fetchData()
    }
  }, [show, fetchData])

  useEffect(() => {
    if (show && !isFetchingEntities && !isFetchedEntities && previousActions.length > 0) {
      const type = action.actionSet.analysisName !== null ? 'analysis' : 'action'
      const name = action.actionSet.analysisName !== null ? action.actionSet.analysisName : action.actionSet.actionName

      const actionSetIdsToImport = updateActionIds(action, previousActions)

      setIsFetchingEntities(true)
      getNoOfEntitiesToOverride(action.actionSet.id, actionSetIdsToImport, type, name)
        .then(({ data }) => {
          setIsFetchedEntities(true)
          setIsFetchingEntities(false)
          dispatch(
            updateJob({
              previousActionsOverrideNos: data,
            }),
          )
        })
        .catch((e) => {
          setIsFetchedEntities(true)
          console.error('Encountered following error while fetching number of overriding entities: ', e)
        })
    }
  }, [dispatch, action.actionSet.id, previousActions, isFetchedEntities, isFetchingEntities, show, action, updateActionIds])

  const handleOnClose = () => {
    dispatch(modalHide(MODAL_IMPORT_ACTIONS))
    setTabValue(0)
    setSelectedValue(null)
    setOverridingEntitiesCount(null)
  }

  const handleTabChange = (tabNumber) => {
    setTabValue(tabNumber)
    setSelectedValue(null)
    setOverridingEntitiesCount(null)
  }

  const getPreviousActions = (previousActionSetId) => {
    dispatch(importActions(job.jobId, action.actionSet.id, previousActionSetId))
      .then(() => {
        handleOnClose()
      })
  }

  const onSelection = (actSetId, overridingEntitiesCnt) => {
    setSelectedValue(actSetId)
    setOverridingEntitiesCount(overridingEntitiesCnt)
  }

  return (
    <Modal
      onClose={() => handleOnClose()}
      open={show}
    >
      <div className={styles.modal}>
        <div className={classNames(styles.flexContainer, styles.flexColDir, styles.base)}>
          <div className={styles.cancel}>
            <CloseIcon
              className={styles.closeIcon}
              onClick={() => handleOnClose()}
            />
          </div>
          <div className={classNames(styles.flexContainer, styles.flexColDir, styles.info)}>
            <div className={styles.heading}>
              Import Actions
            </div>
            <div>
              <div className={styles.tabs}>
                <Tabs
                  onTabChange={value => handleTabChange(value)}
                  tabLabels={['Previous actions', 'Connected actions']}
                  value={tabValue}
                />
              </div>
              <TabPanel index={0} value={tabValue}>
                <PreviousActions
                  isFetchedEntities={isFetchedEntities}
                  onSelection={(actSetId, overridingEntitiesCnt) => onSelection(actSetId, overridingEntitiesCnt)}
                  previousActions={effectiveActions}
                />
              </TabPanel>
              <TabPanel index={1} value={tabValue}>
                <ConnectedJobActions
                  action={action}
                  onSelection={(actSetId, overridingEntitiesCnt) => onSelection(actSetId, overridingEntitiesCnt)}
                />
              </TabPanel>
            </div>
            <div className={styles.importActButton}>
              <Button
                disabled={selectedValue === null}
                onClick={() => getPreviousActions(selectedValue)}
              >
                {_isImportingAction && <Spinner className={styles.spinner} colour="white" size="xSmall" />}
                Import
                {' '}
                {overridingEntitiesCount}
                {' '}
                actions
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ImportActionsModal.propTypes = propTypes

export default hot(ImportActionsModal)
