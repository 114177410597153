import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './CommentStatus.scss'

const CommentStatus = ({ commentStatus, smallText }) => (
  <span className={classNames(
    styles.base,
    styles[`comment-${commentStatus.id}`],
    smallText ? styles.smallText : null,
  )}
  >
    {commentStatus.description}
  </span>
)

CommentStatus.propTypes = {
  commentStatus: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
  }),
  smallText: PropTypes.bool,
}

CommentStatus.defaultProps = {
  commentStatus: {
    description: 'No status',
    id: 0,
  },
  smallText: false,
}

export default CommentStatus
