import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import constants from 'constants'
import OverlayModal from 'components/OverlayModal'
import Animate from 'components/Animate'
import {
  Button, Heading, TextInput,
} from '@deloitte/gel-library'
import {
  formInit, formUpdate, formError,
} from 'actions/forms'
import { getUser } from 'actions/forms/addExternalUsers'
import SelectInput from 'components/Form/components/SelectInput'
import Card from 'components/Card'
import Avatar from 'components/Avatar'
import IconAvatarUnknown from 'icons/IconAvatarUnknown'
import IconInfo from 'icons/IconInfo'
import SubmitButton from 'components/SubmitButton'
import { isDeloitteEmail } from 'utils/validators'
import { isNullOrWhiteSpace } from 'utils/strings'
import { modalHide, modalCreate } from 'actions/modals'
import DeleteIcon from '@material-ui/icons/Delete'
import EmailInput from './EmailInput'
import styles from './AddExternalUserModal.scss'

const MODAL_ID = constants.MODAL_ADD_EXTERNAL_USER
const FORM_ID = constants.FORM_ADD_EXTERNAL_USER
const FORM_SCHEMA = {
  formFields: [
    {
      name: 'emailInput',
    },
    {
      name: 'members',
    },
    {
      name: 'jobName',
    },
  ],
  data: {
    members: [],
  },
}

const fieldsToValidate = [
  'firstName',
  'surname',
  'clientName',
]

export default class AddExternalUserModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    forms: PropTypes.object.isRequired,
    job: PropTypes.shape({
      engagement: PropTypes.shape({
        clientName: PropTypes.string,
        name: PropTypes.string,
      }),
      jobId: PropTypes.string,
      members: PropTypes.arrayOf(PropTypes.shape({
        email: PropTypes.string,
      })),
      name: PropTypes.string,
    }).isRequired,
    modals: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    roles: null,
  }

  componentDidMount() {
    const { dispatch, job } = this.props
    const schema = {
      ...FORM_SCHEMA,
      data: {
        ...FORM_SCHEMA.data,
        jobName: job.name,
      },
    }
    dispatch(formInit(FORM_ID, schema))
  }

  componentDidUpdate(prevProps) {
    const { job, forms } = this.props
    const { job: prevJob } = prevProps
    const { jobName } = forms[FORM_ID]

    if (jobName.value === '' || job.jobId !== prevJob.jobId) {
      this.updateForm('jobName', job.name)
    }
  }

  closeModal = () => {
    const { dispatch } = this.props
    dispatch(modalHide(MODAL_ID))
  }

  updateForm = (field, value) => {
    const { dispatch } = this.props
    dispatch(formUpdate(FORM_ID, { field, data: { error: null, value } }))
  }

  updateMember = (email, field, value) => {
    const { forms } = this.props
    const { members } = forms[FORM_ID]

    const newMembers = members.value.map((m) => {
      return m.email === email
        ? {
          ...m,
          [field]: value,
        }
        : m
    })

    this.updateForm('members', newMembers)
  }

  deleteMember = (email) => {
    const { forms } = this.props
    const { members } = forms[FORM_ID]

    const newMembers = members.value.filter(m => m.email !== email)

    this.updateForm('members', newMembers)
  }

  handleSubmit = () => {
    const { forms, onSubmit } = this.props
    const { members } = forms[FORM_ID]

    const valid = this.validateFields()

    if (valid) {
      onSubmit(members)
    }
  }

  handleAddEmail = () => {
    const { forms, job: { engagement, members: existingMembers }, dispatch } = this.props
    const { emailInput, members } = forms[FORM_ID]

    if (isDeloitteEmail(emailInput.value)) {
      dispatch(formError(FORM_ID, 'emailInput', 'This user is not external'))
      return
    }

    const existingUser = existingMembers.find(x => x.userDetails.email
      && x.userDetails.email.toLowerCase() === emailInput.value.toLowerCase())

    if (existingUser) {
      const { firstName, surname, email } = existingUser.userDetails

      dispatch(formError(FORM_ID,
        'emailInput',
        `${firstName} ${surname} <${email}> already has access to this job`))
      return
    }

    const alreadyAddedToFormUser = members.value.find(x => x.email.toLowerCase() === emailInput.value.toLowerCase())

    if (alreadyAddedToFormUser) {
      const { email } = alreadyAddedToFormUser

      dispatch(formError(FORM_ID,
        'emailInput',
        `User with email ‘${email}’ is already present in the list below`))
      return
    }

    const newMembers = [
      {
        email: emailInput.value,
        firstName: '',
        surname: '',
        clientName: engagement.clientName || '',
        role: null,
        fetched: false,
        exists: false,
        errors: {},
      },
      ...members.value,
    ]

    dispatch(getUser(emailInput.value))

    this.updateForm('members', newMembers)
    this.updateForm('emailInput', '')
  }

  validateFields = (email, field) => {
    const {
      forms, dispatch, roles,
    } = this.props
    const { jobName, members } = forms[FORM_ID]

    let allValid = true

    if (isNullOrWhiteSpace(jobName.value)) {
      dispatch(formError(FORM_ID, 'jobName', 'Job name is required'))
      allValid = false
    }

    const validatedMembers = members.value.map((m) => {
      if (email && email !== m.email) {
        return m
      }

      const errors = {}
      const fields = m.exists ? [] : fieldsToValidate

      if (roles !== null) {
        fields.push('role')
      }

      fields.forEach((f) => {
        if (field && field !== f) {
          errors[f] = m.errors[f]
          return
        }

        const valid = f === 'role'
          ? m[f] !== null
          : m[f]?.trim() !== ''

        allValid = allValid && valid
        errors[f] = valid
          ? ''
          : 'This field is required'
      })

      return {
        ...m,
        errors,
      }
    })

    this.updateForm('members', validatedMembers)
    return allValid
  }

  renderCard = (m) => {
    const { roles } = this.props

    if (!m.fetched) {
      return (
        <div />
      )
    }

    const roleOptions = roles === null
      ? []
      : roles.map(x => ({
        value: x.id,
        label: x.description,
      }))

    if (m.exists) {
      return (
        <>
          <div className={styles.header}>
            <Avatar nameArray={[m.firstName, m.surname]} />
            <div className={styles.names}>
              <div className={styles.email}>
                {`${m.firstName} ${m.surname} (${m.email})`}
              </div>
              <div className={styles.status}>
                <span className={styles.heading}>Registered user</span>
                {` | ${m.organisation}`}
              </div>
            </div>
            <Button
              icon={<DeleteIcon />}
              mode="icon"
              onClick={() => this.deleteMember(m.email)}
            />
          </div>

          {roles !== null && (
            <div className={styles.select}>
              <SelectInput
                error={m.errors.role}
                handleChange={(name, value) => this.updateMember(m.email, name, value)}
                label="Select a role"
                name="role"
                onBlur={() => this.validateFields(m.email, 'role')}
                options={roleOptions}
                value={roleOptions.find(x => x.value === m.role)}
              />
            </div>
          )}
        </>
      )
    }

    return (
      <Fragment>
        <div className={styles.header}>
          <IconAvatarUnknown height={36} width={36} />
          <div className={styles.names}>
            <div className={styles.email}>{m.email}</div>
            <div className={styles.status}>New user</div>
          </div>
          <Button
            icon={<DeleteIcon />}
            mode="icon"
            onClick={() => this.deleteMember(m.email)}
          />

        </div>

        <div className={styles.editUser}>
          <div className="row">
            <div className="col-md-6">
              <TextInput
                error={m.errors.firstName}
                label="First name"
                name={`${m.email}-firstName`}
                onBlur={() => this.validateFields(m.email, 'firstName')}
                onChange={value => this.updateMember(m.email, 'firstName', value)}
                value={m.firstName}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                error={m.errors.surname}
                label="Last name"
                name={`${m.email}-surname`}
                onBlur={() => this.validateFields(m.email, 'surname')}
                onChange={value => this.updateMember(m.email, 'surname', value)}
                value={m.surname}
              />
            </div>
          </div>

          <TextInput
            error={m.errors.clientName}
            label="Company name"
            name={`${m.email}-clientName`}
            onBlur={() => this.validateFields(m.email, 'clientName')}
            onChange={value => this.updateMember(m.email, 'clientName', value)}
            value={m.clientName}
          />

          {roles !== null && (
            <SelectInput
              error={m.errors.role}
              handleChange={(name, value) => this.updateMember(m.email, name, value)}
              label="Select a role"
              name="role"
              onBlur={() => this.validateFields(m.email, 'role')}
              options={roleOptions}
              value={roleOptions.find(x => x.value === m.role)}
            />
          )}
        </div>

      </Fragment>
    )
  }

  render() {
    const {
      modals, forms, job, dispatch,
    } = this.props
    const form = forms[FORM_ID]

    if (!form) {
      return null
    }

    const {
      emailInput, members, jobName, _isPosting,
    } = forms[FORM_ID]

    const fetchedMembers = members.value
      .filter(x => x.fetched)

    const isSubmitDisabled = members.value.length === 0

    return (
      <OverlayModal
        base={10}
        disable={false}
        id={constants.MODAL_ADD_EXTERNAL_USER}
        init={() => dispatch(modalCreate(MODAL_ID))}
        modals={modals}
        onClose={this.closeModal}
        push={4}
      >
        <Animate name="fade">
          <div className={styles.base}>
            <Heading className={styles.topHeading} level={5}>
              Add new client users to
              <br />
              ‘
              {job.name}
              ’
            </Heading>

            <div className={styles.emailInputContainer}>
              <EmailInput
                error={emailInput.error}
                label="Enter your client’s email address to invite"
                name="emailInput"
                onChange={value => this.updateForm('emailInput', value)}
                onSubmit={this.handleAddEmail}
                value={emailInput.value}
              />
            </div>

            <div className={classNames(
              styles.animateHiddenSection,
              fetchedMembers.length > 0 ? styles.animateVisibleSection : 0,
            )}
            >

              <p className={styles.usersHeading}>
                Selected users (
                {fetchedMembers.length}
                )
              </p>
              {members.value.map((m) => {
                const isExisting = m.fetched && m.exists
                const isNew = m.fetched && !m.exists

                return (
                  <Card
                    noPadding
                    className={classNames(styles.userCard,
                      isExisting ? styles.existingUser : null,
                      isNew ? styles.newUser : null)}
                    key={m.email}
                  >
                    {this.renderCard(m)}
                  </Card>
                )
              })}

              <div className={styles.information}>
                <div className={styles.header}>
                  <IconInfo fill="#6d6d6d" height={20} width={20} />
                  <p className={styles.heading}>Important information</p>
                </div>
                <div className={styles.body}>
                  <p className={styles.heading}>
                    Edit your job name to ensure it is appropriate for client viewing
                  </p>
                  <TextInput
                    error={jobName.error}
                    label="Job name"
                    name="jobName"
                    onChange={value => this.updateForm('jobName', value)}
                    value={jobName.value}
                  />

                  {job.engagement.name !== 'Unallocated' && (
                    <div className={styles.engagementDetails}>
                      <table>
                        <tbody>
                          <tr>
                            <td className={styles.heading}>Engagement</td>
                            <td>{job.engagement.name}</td>
                          </tr>
                          <tr>
                            <td className={styles.heading}>Client</td>
                            <td>{job.engagement.clientName}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  <p className={styles.heading}>
                    What happens after the invitation is sent?
                  </p>
                  <p>
                    Once the request is submitted, the specified client users will receive
                    an email invitation with a link to the job they have been added to.
                    If the specified client users are new to InsightBox, they will receive
                    an additional welcome email containing an account activation link to enable
                    their enrolment into our Multi-Factor Authentication system.
                  </p>
                </div>
              </div>

              <div className={styles.buttons}>
                <SubmitButton
                  disabled={isSubmitDisabled}
                  onClick={this.handleSubmit}
                  submitting={_isPosting}
                  submittingText="Adding users"
                >
                  Send invitation
                </SubmitButton>
                <Button mode="flat" onClick={this.closeModal}>Cancel</Button>
              </div>

            </div>
          </div>
        </Animate>
      </OverlayModal>
    )
  }
}
