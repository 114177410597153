import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import Container from 'components/layout/Grid/Container'
import PanelFilters from 'components/PanelFilters'
import ProductBanner from 'components/ProductBanner'
import { Heading, DataTableSimple } from '@deloitte/gel-library'
import Card from 'components/Card'
import SolutionImageIcon from 'components/SolutionImageIcon'
import { sortByFunction } from 'utils/arrays'
import LinesEllipsis from 'react-lines-ellipsis'
import { resume } from 'actions/jobs'
import { jobExecutionFailed } from 'utils/business/workflow'
import { roles } from 'utils/business/jobs'
import { notify } from 'actions/app'
import classNames from 'classnames'
import styles from './InsightsView.scss'

const solutionPropType = PropTypes.shape({
  meta: PropTypes.object,
  name: PropTypes.string,
})

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  isCustomReport: PropTypes.bool,
  jobs: PropTypes.arrayOf(PropTypes.shape({
    jobId: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
  })),
  reportGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  solution: solutionPropType,
}

const defaultProps = {
  jobs: [],
  reportGroups: [],
  solution: null,
  isCustomReport: false,
}

const dateFormat = 'DD MMM YYYY'

const SolutionCardItem = ({ solution }) => {
  return (
    <div className={styles.solutionCardItem}>
      <SolutionImageIcon className={styles.smallImageContainer} solution={solution} />
      <div className={styles.textContent}>
        <p className={styles.heading}>{solution.meta.displayName}</p>
        <p className={styles.description}>{solution.meta.description}</p>
      </div>
    </div>
  )
}

SolutionCardItem.propTypes = {
  solution: PropTypes.shape({
    meta: PropTypes.shape({
      description: PropTypes.string,
      displayName: PropTypes.string,
    }),
  }).isRequired,
}

const InsightsView = ({
  jobs, reportGroups, solution, dispatch, isCustomReport,
}) => {
  const userId = useSelector(state => state.app.user.id)

  const handleRegularJobClick = (job) => {
    if (jobExecutionFailed(job)) {
      dispatch(notify('This job has failed, please contact your Deloitte representative for assistance.', 'error'))
    } else {
      dispatch(resume({ jobId: job.jobId }))
    }
  }

  const handleCustomJobClick = (job) => {
    const member = job.members.find(m => m.id === userId)
    const suffix = member?.role?.name === roles.ExternalAssignedActionsEditor ? 'actions' : 'reports'
    dispatch(push(`/custom/insights/${job.jobId}/${suffix}`))
  }

  const handleJobClick = isCustomReport ? handleCustomJobClick : handleRegularJobClick

  const [selectedFilterId, setSelectedFilterId] = useState(0)

  const isSubscriptionJob = j => j.isClientJob
    && j.members?.find(m => m.id === userId).role.name === roles.JobExternalEditor

  const sortByModified = sortByFunction(j => new Date(j.lastModifiedDate), true)
  const clientJobs = jobs.filter(j => isSubscriptionJob(j)).sort(sortByModified)
  const nonClientJobs = jobs.filter(j => !isSubscriptionJob(j)).sort(sortByModified)

  const filters = [
    {
      id: 0,
      name: 'Your jobs',
      filterLevel: 'Low',
      count: clientJobs.length,
      jobs: clientJobs,
    },
    {
      id: 1,
      name: 'Shared with you',
      filterLevel: 'Low',
      count: nonClientJobs.length,
      jobs: nonClientJobs,
    },
  ]

  const showFilters = filters[0].count > 0 && filters[1].count > 0
  const jobData = showFilters ? filters[selectedFilterId].jobs : jobs

  const userIsAssignedActionsEditor = (job) => {
    const member = job.members.find(m => m.id === userId)
    return member?.role?.name === roles.ExternalAssignedActionsEditor
  }

  const jobColumns = [
    {
      heading: 'Name',
      cellExtractor: x => (
        <div className={classNames(x.engagement?.active ? styles.activeRow : null)}>
          <div>
            <span className={styles.jobName}>{x.name}</span>
            {x.engagement?.active && (
              <span className={styles.active}>Active</span>
            )}
          </div>
          {!userIsAssignedActionsEditor(x) && (
            <div className={styles.analysisCount}>{`${x.analyses.length} report${x.analyses.length === 1 ? '' : 's'}`}</div>
          )}
        </div>
      ),
      key: 'name',
    },
    {
      heading: 'Analysis period',
      cellExtractor: x => `${moment(x.periodStartDate).format(dateFormat)} – ${moment(x.periodEndDate).format(dateFormat)}`,
      key: 'period',
    },
    { heading: 'Date processed', cellExtractor: x => x.lastExecutionDate?.format(dateFormat) || 'Not executed', key: 'processed' },
  ]

  const reportColumns = [
    {
      heading: 'Name',
      cellExtractor: x => (
        <div>
          {x.name}
          {!userIsAssignedActionsEditor(x) && (
            <div className={styles.analysisCount}>{`${x.reports.length} report${x.reports.length === 1 ? '' : 's'}`}</div>
          )}
        </div>
      ),
      key: 'name',
    },
    {
      heading: 'Description',
      cellExtractor: x => <LinesEllipsis text={x.description} />,
      key: 'period',
    },
    {
      heading: 'Last modified',
      cellExtractor: x => `${moment(x.updatedDate).format(dateFormat)}`,
      key: 'modified',
    },
  ]

  return (
    <Fragment>
      <ProductBanner
        blurb={(
          <Fragment>
            {solution.meta.description}
            {!isCustomReport && (
            <Link
              className={styles.solutionLink}
              to={`/solution/${solution.name}`}
            >
              About the solution
            </Link>
            )}
          </Fragment>
        )}
        className={styles.banner}
        imageContent={isCustomReport ? null : <SolutionImageIcon className={styles.imageContainer} solution={solution} />}
        name={solution.meta.displayName}
      />

      <Container>

        {showFilters
          ? <PanelFilters filters={filters} onSelect={f => setSelectedFilterId(f.id)} selectedId={selectedFilterId} />
          : <Heading level={8}>{ isCustomReport ? 'Your custom reports' : 'Your jobs'}</Heading>
        }

        <Card noPadding className={styles.cardBottomMargin}>
          <DataTableSimple
            clickable
            className={styles.jobList}
            columns={isCustomReport ? reportColumns : jobColumns}
            data={isCustomReport ? reportGroups : jobData}
            keyExtractor={isCustomReport ? g => g.id : job => job.jobId}
            onRowClick={j => handleJobClick(j)}
            sortFunction={sortByFunction(x => x.updatedDate, true)}
            spacing="large"
          />
        </Card>

      </Container>

    </Fragment>
  )
}

InsightsView.propTypes = propTypes
InsightsView.defaultProps = defaultProps

export default InsightsView
