import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@deloitte/gel-library'
import { useSelector, useDispatch } from 'react-redux'
import LabelForm from 'actionHub/views/LabelForm'
import { isConditionallyDisabledFromLabelValues, prepareValueForComparison } from 'actionHub/utils/dependencies'
import { isNullOrWhiteSpace } from 'utils/strings'
import { MODAL_BULK_ACTION_UPDATES } from 'constants/forms'
import SubmitButton from 'components/SubmitButton'
import Checkbox from 'components/Form/components/Checkbox'
import { formatDateFromStringShort } from 'utils/dates'
import { resetLabels, updateSignOffDetails } from 'actionHub/redux/actions'
import { putTasksSignOffDetails } from 'actionHub/utils/actionHubApi'
import Spinner from 'components/Spinner'
import useApp from '../../hooks/useApp'
import styles from './CaseTestingConclusion.scss'
import useTask from '../../actionHub/hooks/useTask'

const propTypes = {
  actionSetId: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({
    comment: PropTypes.string,
  })),
  disabled: PropTypes.bool.isRequired,
  jobId: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  selectedTaskCount: PropTypes.number,
  setLabelValue: PropTypes.func.isRequired,
  submitLabels: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
  validateLabel: PropTypes.func.isRequired,
}

const defaultProps = {
  comments: [],
  selectedTaskCount: 0,
}

function CaseTestingConclusion({
  actionSetId,
  taskId,
  validateLabel,
  setLabelValue,
  disabled,
  object,
  selectedTaskCount,
  submitLabels,
  comments,
  jobId,
}) {
  const { showNotification } = useApp()
  const dispatch = useDispatch()

  // User-related constants
  const user = useSelector(state => state.app.user)

  // Task-related constants
  const { taskTitle } = useTask(actionSetId, taskId)
  const task = useSelector(state => state.actionHub.tasks[taskId])
  const allowSignOff = useSelector(state => state.actionHub.taskLevel?.allowSignOff)

  const isReviewer = task?.reviewerId === user.id

  // UI state constants
  const [checked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  // Modal-related constant
  const modals = useSelector(state => state.modals)
  const isBulkUpdateModal = MODAL_BULK_ACTION_UPDATES in modals && modals[MODAL_BULK_ACTION_UPDATES].show

  const isSignedOff = Boolean(task?.signOffName && task?.signOffDate)

  useEffect(() => {
    if (task) {
      setIsLoading(false)
    }
  }, [task])

  const handleSignOff = useCallback(async () => {
    const currentDate = new Date().toISOString()
    const newSignOffName = `${user.firstName} ${user.surname}`

    try {
      await putTasksSignOffDetails(actionSetId, [taskId], newSignOffName, currentDate)
      dispatch(updateSignOffDetails(taskId, newSignOffName, currentDate))
      showNotification(`Action ${taskTitle} complete.`)
    } catch (e) {
      showNotification('Could not sign off, please refresh the page and contact support if issues persist.', e, 'error')
    }
  }, [user.firstName, user.surname, dispatch, taskId, actionSetId, showNotification, taskTitle])

  const handleReset = () => {
    dispatch(resetLabels(taskId))
  }

  if (!object) { return null }

  const applyValidationValues = (obj) => {
    if (!obj.labels) { return [] }

    return obj.labels.map((l) => {
      if (l.dependencies === null) { return l }

      const newDependencies = l.dependencies.map((d) => {
        if (d.dataColumnName === null && d.labelName === null) { return d }
        if (d.dataColumnName !== null) {
          return {
            ...d,
            validationValue: obj.taskData && prepareValueForComparison(l.labelType, obj.taskData[d.dataColumnName]),
          }
        }
        return {
          ...d,
          validationValue: obj.taskData && obj.labels.find(label => label.name === d.labelName)?.value,
        }
      })

      return { ...l, dependencies: newDependencies }
    })
  }

  const labelsWithValidationData = applyValidationValues(object)
    .map(l => ({
      ...l,
      isDisabled: isConditionallyDisabledFromLabelValues(l, object.labels),
      isComplete: !isNullOrWhiteSpace(l.value),
    }))

  const areLabelsValid = object.labels && object.labels.every(l => !('valid' in l) || l.valid)

  const requiredLabels = labelsWithValidationData.filter(l => l.isRequired && !l.isDisabled)
  const completedRequiredLabels = requiredLabels.filter(l => l.isComplete)
  const areAllRequiredLabelsCompleted = requiredLabels.length === completedRequiredLabels.length

  const handleSubmit = async () => {
    try {
      await putTasksSignOffDetails(actionSetId, [taskId], null, null)
      dispatch(updateSignOffDetails(taskId, null, null))
      submitLabels()
    } catch (e) {
      showNotification('Could not clear sign-off details, please refresh the page and try again.', e, 'error')
    }
  }

  const renderLabelForm = () => (
    <LabelForm
      actionSetId={actionSetId}
      comments={comments}
      disabled={disabled}
      isModified={object._isLabelsModified}
      jobId={jobId}
      labels={labelsWithValidationData}
      setLabelValue={setLabelValue}
      taskId={taskId}
      validateLabel={validateLabel}
    />
  )

  const renderRegularButtons = () => {
    if (isLoading) {
      return <Spinner size="small" />
    }

    let signOffContent = null

    if (allowSignOff) {
      if (!isSignedOff) {
        signOffContent = isReviewer ? (
          <Button
            disabled={!areAllRequiredLabelsCompleted || !areLabelsValid || !isReviewer}
            onClick={handleSignOff}
            style={{ padding: '6px 12px' }}
          >
            SIGN OFF
          </Button>
        ) : <div> </div>
      } else {
        signOffContent = (
          <div className={styles.signOffText}>
            {`Signed off by ${task.signOffName} on ${formatDateFromStringShort(task.signOffDate)}`}
          </div>
        )
      }
    }

    const hasFilledLabels = object.labels && object.labels.some(label => !isNullOrWhiteSpace(label.value))

    return (
      <div className={styles.footerContent}>
        {signOffContent}
        <div className={styles.buttonGroup}>
          <div
            className={styles.resetButton}
            onClick={hasFilledLabels ? handleReset : null}
            style={{
              color: hasFilledLabels ? '#26890D' : '#00000042',
              cursor: hasFilledLabels ? 'pointer' : 'default',
            }}
          >
            RESET
          </div>
          <Button
            disabled={!object._isLabelsModified || object._isSubmittingLabels || !areLabelsValid || disabled}
            onClick={handleSubmit}
            style={{ padding: '6px 12px' }}
          >
            SAVE FINDINGS
          </Button>
        </div>
      </div>
    )
  }

  const renderBulkUpdateButtons = () => (
    <>
      <div className={styles.checkboxDiv}>
        <Checkbox
          checked={checked}
          className={styles.checkboxText}
          handleChange={() => setIsChecked(!checked)}
          label="Confirm that you want to bulk update selected cases. Any fields filled out will override existing values in the cases you have selected."
          name="bulkUpdateInfo"
        />
      </div>
      <div className={styles.bulkUpdateSubmitBtn}>
        <SubmitButton
          disabled={!object._isLabelsModified || object._isSubmittingLabels || !areLabelsValid || disabled || !checked}
          onClick={handleSubmit}
          submittingText="Applying"
        >
          {`UPDATE ${selectedTaskCount} ENTITIES`}
        </SubmitButton>
      </div>
    </>
  )

  return (
    <div className={styles.container}>
      <div className={styles.labelContent}>
        {object.labels && renderLabelForm()}
      </div>
      <div className={styles.buttonRow}>
        {isBulkUpdateModal ? renderBulkUpdateButtons() : renderRegularButtons()}
      </div>
    </div>
  )
}

CaseTestingConclusion.propTypes = propTypes
CaseTestingConclusion.defaultProps = defaultProps

export default CaseTestingConclusion
