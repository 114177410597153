import React from 'react'
import PropTypes from 'prop-types'
import { group } from 'd3-array'
import { sortByFunction } from 'utils/arrays'
import CardList from 'components/CardList'
import ActionItem from 'views/ActionItem/ActionItem'
import { useDispatch } from 'react-redux'
import { formatDateTimeFromStringShort } from 'utils/dates'
import { push } from 'connected-react-router'
import { Card } from '@deloitte/gel-library'
import classNames from 'classnames'
import { formatDate } from 'utils/utils'
import { jobGetMemberGroups } from 'utils/business/jobs'
import GroupedAvatar from 'components/GroupedAvatar'
import styles from './ActionGroup.scss'

const propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  canEdit: PropTypes.bool.isRequired,
  clientTeam: PropTypes.arrayOf(PropTypes.string),
  entityMeta: PropTypes.object,
  job: PropTypes.shape({
    hasExpired: PropTypes.bool,
    members: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  showHeader: PropTypes.bool,
}

const defaultProps = {
  clientTeam: [],
  entityMeta: null,
  showHeader: false,
}

function ActionGroup({
  job,
  actions,
  showHeader,
  canEdit,
  clientTeam,
  entityMeta,
}) {
  const { externalTeam } = jobGetMemberGroups(job?.members)
  const dispatch = useDispatch()
  const actionsWithExecution = actions.filter(a => a?.execution?.id)
  const actionsWithoutExecution = actions.filter(a => !a?.execution?.id && a?.executionId)

  const groupedActionsWithExecution = Array.from(
    group(actionsWithExecution, a => a.execution.id),
    ([key, value]) => ({
      key,
      value,
    }),
  )

  const groupedActionsWithoutExecution = Array.from(
    group(actionsWithoutExecution, a => a.executionId),
    ([key, value]) => ({
      key,
      value,
    }),
  )
  const groupedActions = [...groupedActionsWithExecution, ...groupedActionsWithoutExecution]
    .sort(sortByFunction(x => x.value[0]?.execution?.executionDate, true))

  return groupedActions.map((g) => {
    const firstAction = g.value[0]
    const creatorEmail = firstAction.actionSet
      ? firstAction.actionSet.createdBy
      : firstAction.createdBy
    const creator = job?.members?.find(m => m.userDetails.email === creatorEmail)
    const creatorDetails = creator ? creator.userDetails : {}

    return (
      <React.Fragment key={g.key}>
        {entityMeta && (
          <div>
            <Card noPadding className={styles.actionHeader}>
              <div className={styles.row}>
                <div className={classNames(styles.column, styles.firstColumn)}>
                  {entityMeta.entityMetaName}
                </div>
                <div className={styles.column}>{entityMeta.entityMetaCode}</div>
                <div className={styles.column}>{formatDate(entityMeta.dueDate)}</div>
                <div className={classNames(styles.column, styles.clientTeamColumn)}>
                  <span>
                    <GroupedAvatar
                      members={externalTeam.filter(m => clientTeam.includes(m.id))}
                    />
                  </span>
                </div>
                <div className={styles.column}>
                  <span className={styles.status}>{entityMeta.status}</span>
                </div>
                <div className={styles.endColumn} />
              </div>
            </Card>
          </div>
        )}

        <CardList
          action={g}
          className={classNames(styles.base, entityMeta ? styles.cardList : null)}
          contentExtractor={a => (
            <ActionItem
              action={a}
              dispatch={dispatch}
              isEntityMeta={!!entityMeta}
              key={a.actionSet ? a.actionSet.id : a.id}
            />
          )}
          data={g.value}
          entityMeta={entityMeta ?? null}
          header={
            showHeader ? (
              <div className={styles.actionHeader}>
                <p>
                  Previous run
                  {' '}
                  {formatDateTimeFromStringShort(firstAction.execution.executionDate)}
                </p>
                <p>
                  <strong>
                    Created by
                    {' '}
                    {` ${creatorDetails.firstName} ${creatorDetails.surname}`}
                  </strong>
                </p>
              </div>
            ) : null
          }
          /* Pass a keyExtractor for the list items. */
          keyExtractor={a => (a.actionSet ? a.actionSet.id : a.id)}
          onClick={a => dispatch(push(`/action/${a.actionSet ? a.actionSet.id : a.id}`))}
          showImportActionButton={canEdit && !showHeader && !job.hasExpired}
        />
      </React.Fragment>
    )
  })
}

ActionGroup.propTypes = propTypes
ActionGroup.defaultProps = defaultProps

export default ActionGroup

