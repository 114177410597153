import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Accordion, DropdownMenu } from '@deloitte/gel-library'
import { groupBy, sortByFunction } from 'utils/arrays'
import { isNullOrWhiteSpace } from 'utils/strings'
import { MODAL_BULK_ACTION_UPDATES } from 'constants/forms'
import styles from './LabelForm.scss'
import LabelField from '../LabelField'
import useTask from '../../hooks/useTask'
import CommentStatus from '../../components/CommentStatus/CommentStatus'
import Comments from '../../components/Comments'

const labelFormPropTypes = {
  actionSetId: PropTypes.string.isRequired,
  className: PropTypes.string,
  comments: PropTypes.arrayOf(PropTypes.shape({
    comment: PropTypes.string,
  })).isRequired,
  disabled: PropTypes.bool.isRequired,
  isModified: PropTypes.bool,
  jobId: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.object).isRequired,
  setLabelValue: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
  validateLabel: PropTypes.func.isRequired,
  valueForSort: PropTypes.string,
}

const labelFormDefaultProps = {
  className: null,
  isModified: false,
  valueForSort: 'group',
}

function LabelForm({
  actionSetId, taskId, className, disabled, labels, setLabelValue, validateLabel, isModified, valueForSort, comments, jobId,
}) {
  const modals = useSelector(state => state.modals)
  const allowClientComments = useSelector(state => state.actionHub.taskLevel?.allowClientComments)
  const commentStatuses = useSelector(state => state.actionHub.commentStatuses)
  const { setTaskCommentStatus } = useTask(actionSetId, taskId)
  const task = useSelector(state => state.actionHub.tasks[taskId])

  const [expandedAccordions, setExpandedAccordions] = useState({})

  const handleAccordionChange = useCallback(accordionId => (_, isExpanded) => {
    setExpandedAccordions(prev => ({
      ...prev,
      [accordionId]: isExpanded,
    }))
  }, [])

  const [modifiedFields, setModifiedFields] = useState([])

  const isBulkUpdateModal = MODAL_BULK_ACTION_UPDATES in modals && modals[MODAL_BULK_ACTION_UPDATES].show

  const warnIfDirty = useCallback((event) => {
    if (isModified) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = 'If you leave without saving, you will lose your changes'
    }
  }, [isModified])

  useEffect(() => {
    window.addEventListener('beforeunload', warnIfDirty)
    return () => {
      window.removeEventListener('beforeunload', warnIfDirty)
    }
  }, [warnIfDirty])

  const fields = labels.map((l) => {
    let value
    if (isBulkUpdateModal) {
      value = isModified ? l.value : ''
    } else {
      value = l.type === 'BIT' ? l.value : (l.value || '')
    }

    return {
      id: l.id,
      group: l.labelGroup,
      name: `${l.displayName}`,
      displayOrder: l.displayOrder,
      isRequired: l.isRequired,
      isComplete: l.isComplete,
      isDisabled: l.isDisabled,
      field: {
        type: l.labelType,
        description: l.description,
        formKey: `${l.id}`,
        options: l.options && l.options.map(x => ({ label: x, value: x })),
      },
      formData: {
        value,
        valid: l.valid ?? true,
        error: l.error || null,
      },
      dependencies: l.dependencies,
    }
  }).sort(sortByFunction(x => x.displayOrder))

  const groupedFields = groupBy(fields, valueForSort)

  if (groupedFields.length === 1 && isNullOrWhiteSpace(groupedFields[0].key)) {
    return (
      <div className={classNames(styles.base, styles.singleGroupSection, className)}>
        {fields.map(f => (
          <LabelField
            disabled={disabled}
            field={f}
            isBulkUpdateModal={isBulkUpdateModal}
            key={f.id}
            modifiedFields={modifiedFields}
            setModifiedFields={setModifiedFields}
            setValue={setLabelValue}
            validateLabel={validateLabel}
          />
        ))}
      </div>
    )
  }

  const renderGroupAccordion = ({ key: groupName, values: groupLabels }, index) => {
    const accordionId = `group_${index}`
    const requiredLabels = groupLabels.filter(l => l.isRequired && !l.isDisabled)
    const completeLabels = isBulkUpdateModal
      ? requiredLabels.filter(l => l.isComplete && modifiedFields.includes(l.id))
      : requiredLabels.filter(l => l.isComplete)
    const completeStatus = requiredLabels.length > 0
      ? `${completeLabels.length} of ${requiredLabels.length} required`
      : ''

    return (
      <Accordion
        className={styles.accordion}
        detailsClassName={styles.formValues}
        expanded={!!expandedAccordions[accordionId]}
        heading={(
          <p className={styles.groupHeading}>
            {groupName}
            <span className={styles.itemCount}>{`${groupLabels.length} ${groupLabels.length === 1 ? 'item' : 'items'}`}</span>
            <span className={styles.spacer} />
            <span className={styles.complete}>{completeStatus}</span>
          </p>
        )}
        key={groupName}
        onChange={handleAccordionChange(accordionId)}
      >
        {groupLabels.map(f => (
          <LabelField
            disabled={disabled}
            field={f}
            isBulkUpdateModal={isBulkUpdateModal}
            key={f.id}
            modifiedFields={modifiedFields}
            setModifiedFields={setModifiedFields}
            setValue={setLabelValue}
            validateLabel={validateLabel}
          />
        ))}
      </Accordion>
    )
  }

  const commentStatusOptions = commentStatuses
    .filter(status => status.id !== 0 && status.id !== 2)
    .map(status => ({
      key: `${status.id}`,
      label: <CommentStatus smallText commentStatus={status} />,
      onClick: () => setTaskCommentStatus(status.id),
    }))

  const getCommentStatus = () => {
    const status = commentStatuses.find(s => s.id === task?.commentStatusId)
    return <CommentStatus smallText commentStatus={status} />
  }

  const renderExternalCommentsAccordion = () => {
    const accordionId = 'externalComments'
    const externalCommentCount = comments.filter(comment => comment.commentTypeId === 1 || comment.commentTypeId === 2).length

    const handleDropdownClick = (e) => {
      e.stopPropagation()
    }

    return (
      <Accordion
        className={styles.accordion}
        detailsClassName={styles.formValues}
        expanded={!!expandedAccordions[accordionId]}
        heading={(
          <p className={styles.groupHeading}>
            Client comments
            <span className={styles.itemCount}>
              {`${externalCommentCount} ${externalCommentCount === 1 ? 'item' : 'items'}`}
            </span>
            <span className={styles.spacer} />
            <div onClick={handleDropdownClick}>
              <DropdownMenu
                disabled={disabled}
                displayText={getCommentStatus()}
                options={commentStatusOptions}
              />
            </div>
          </p>
        )}
        onChange={handleAccordionChange(accordionId)}
      >
        <Comments
          actionSetId={actionSetId}
          commentType={2}
          disabled={disabled}
          jobId={jobId}
          taskId={taskId}
          variant={{ attachable: true, resolvable: true }}
        />
      </Accordion>
    )
  }

  return (
    <div className={classNames(styles.base, className)}>
      {groupedFields.map(renderGroupAccordion)}
      {allowClientComments && renderExternalCommentsAccordion()}
    </div>
  )
}

LabelForm.propTypes = labelFormPropTypes
LabelForm.defaultProps = labelFormDefaultProps

export default LabelForm
