export const ACTION_HUB_FETCHING_TASKS = 'ACTION_HUB_FETCHING_TASKS'
export const ACTION_HUB_FETCHING_TAGS = 'ACTION_HUB_FETCHING_TAGS'
export const ACTION_HUB_FETCHED_TASKS = 'ACTION_HUB_FETCHED_TASKS'
export const ACTION_HUB_FETCHED_TAGS = 'ACTION_HUB_FETCHED_TAGS'
export const ACTION_HUB_FETCHING_ACTION = 'ACTION_HUB_FETCHING_ACTION'
export const ACTION_HUB_FETCHED_ACTION = 'ACTION_HUB_FETCHED_ACTION'
export const ACTION_HUB_FETCHED_TASK_META = 'ACTION_HUB_FETCHED_TASK_META'
export const ACTION_HUB_FETCHING_TASK_DATA = 'ACTION_HUB_FETCHING_TASK_DATA'
export const ACTION_HUB_FETCHED_TASK_DATA = 'ACTION_HUB_FETCHED_TASK_DATA'
export const ACTION_HUB_FETCHED_TASK_DATA_NO_DATA = 'ACTION_HUB_FETCHED_TASK_DATA_NO_DATA'
export const ACTION_HUB_FETCHING_DATA_DETAIL = 'ACTION_HUB_FETCHING_DATA_DETAIL'
export const ACTION_HUB_FETCHED_DATA_DETAIL = 'ACTION_HUB_FETCHED_DATA_DETAIL'
export const ACTION_HUB_UPDATE_TASK_LABEL_VALUE = 'ACTION_HUB_UPDATE_TASK_LABEL_VALUE'
export const ACTION_HUB_UPDATE_TAG_LABEL_VALUE = 'ACTION_HUB_UPDATE_TAG_LABEL_VALUE'
export const ACTION_HUB_SUBMITTING_TASK_LABELS = 'ACTION_HUB_SUBMITTING_TASK_LABELS'
export const ACTION_HUB_SUBMITTED_TASK_LABELS = 'ACTION_HUB_SUBMITTED_TASK_LABELS'
export const ACTION_HUB_SUBMITTED_TAG_LABELS = 'ACTION_HUB_SUBMITTED_TAG_LABELS'
export const ACTION_HUB_VALIDATE_TASK_LABEL = 'ACTION_HUB_VALIDATE_TASK_LABEL'
export const ACTION_HUB_VALIDATE_TAG_LABEL = 'ACTION_HUB_VALIDATE_TAG_LABEL'
export const ACTION_HUB_SUBMITTED_TASK_ASSIGNEE = 'ACTION_HUB_SET_TASK_ASSIGNEE'
export const ACTION_HUB_SUBMITTED_TASK_STATUS = 'ACTION_HUB_SET_TASK_STATUS'
export const ACTION_HUB_SUBMITTED_TASK_PRIORITY = 'ACTION_HUB_SET_TASK_PRIORITY'
export const ACTION_HUB_IMPORTING_ACTIONS = 'ACTION_HUB_IMPORTING_ACTIONS'
export const ACTION_HUB_IMPORTED_ACTIONS = 'ACTION_HUB_IMPORTED_ACTIONS'
export const ACTION_HUB_ERRORED_IMPORTING_ACTIONS = 'ACTION_HUB_ERRORED_IMPORTING_ACTIONS'
export const ACTION_HUB_SUBMITTING_TASK_COMMENT = 'ACTION_HUB_SUBMITTING_TASK_COMMENT'
export const ACTION_HUB_SUBMITTED_TASK_COMMENT = 'ACTION_HUB_SUBMITTED_TASK_COMMENT'
export const ACTION_HUB_SUBMITTED_TASK_TAG = 'ACTION_HUB_SUBMITTED_TASK_TAG'
export const ACTION_HUB_UPDATE_SELECTED_TASKS = 'ACTION_HUB_UPDATE_SELECTED_TASKS'
export const ACTION_HUB_FETCH_SELECTED_TASKS = 'ACTION_HUB_FETCH_SELECTED_TASKS'
export const ACTION_HUB_SUBMITTED_TASK_ACTIONSTATE = 'ACTION_HUB_SUBMITTED_TASK_ACTIONSTATE'
export const ACTION_HUB_UPDATE_TASK_DETAILS = 'ACTION_HUB_UPDATE_TASK_DETAILS'
export const ACTION_HUB_UPDATING_TASK_DETAILS = 'ACTION_HUB_UPDATING_TASK_DETAILS'
export const ACTION_HUB_ADDING_TASK = 'ACTION_HUB_ADDING_TASK'
export const ACTION_HUB_REMOVE_TASK = 'ACTION_HUB_REMOVE_TASK'
export const ACTION_HUB_UPDATE_TASKS_WITH_CLIENT = 'ACTION_HUB_UPDATE_TASKS_WITH_CLIENT'

// Tags
export const ACTION_HUB_TAG_CREATED = 'ACTION_HUB_TAG_CREATED'
export const ACTION_HUB_TAG_COMMENTS_UPDATED = 'ACTION_HUB_TAG_COMMENTS_UPDATED'
export const ACTION_HUB_UPDATE_TAG_DATA = 'ACTION_HUB_UPDATE_TAG_DATA'
export const ACTION_HUB_FETCHING_TAG_TASKS = 'ACTION_HUB_FETCHING_TAG_TASKS'
export const ACTION_HUB_FETCHED_TAG_TASKS = 'ACTION_HUB_FETCHED_TAG_TASKS'

// Data level
export const ACTION_HUB_UPDATE_DATA_LABEL_VALUE = 'ACTION_HUB_UPDATE_DATA_LABEL_VALUE'
export const ACTION_HUB_VALIDATE_DATA_LABEL = 'ACTION_HUB_VALIDATE_DATA_LABEL'
export const ACTION_HUB_SUBMITTING_DATA_LABELS = 'ACTION_HUB_SUBMITTING_DATA_LABELS'
export const ACTION_HUB_SUBMITTED_DATA_LABELS = 'ACTION_HUB_SUBMITTED_DATA_LABELS'
export const ACTION_HUB_SUBMITTING_DATA_COMMENT = 'ACTION_HUB_SUBMITTING_DATA_COMMENT'
export const ACTION_HUB_SUBMITTED_DATA_COMMENT = 'ACTION_HUB_SUBMITTED_DATA_COMMENT'
export const ACTION_HUB_SELECTED_TAB_UPDATE = 'ACTION_HUB_SELECTED_TAB_UPDATE'
export const ACTION_HUB_FETCHED_TAG_DETAILS = 'ACTION_HUB_FETCHED_TAG_DETAILS'
export const ACTION_HUB_UPDATED_TASKS_ADD_TAG = 'ACTION_HUB_UPDATED_TASKS_ADD_TAG'
export const ACTION_HUB_FETCHED_UPDATED_TASKS_TOKENS = 'ACTION_HUB_FETCHED_UPDATED_TASKS_TOKENS'
export const ACTION_HUB_SUBMITTING_TASK_LABELS_FAILED = 'ACTION_HUB_SUBMITTING_TASK_LABELS_FAILED'
export const ACTION_HUB_SUBMITTING_TAG_LABELS = 'ACTION_HUB_SUBMITTING_TAG_LABELS'

export const ACTION_HUB_TASKLIST_SCROLL_LOCATION = 'ACTION_HUB_TASKLIST_SCROLL_LOCATION'
export const ACTION_HUB_TASKLIST_SEARCH_QUERY = 'ACTION_HUB_TASKLIST_SEARCH_QUERY'

export const ACTION_HUB_UPDATE_IS_COMMENT_REQUIRED = 'ACTION_HUB_UPDATE_IS_COMMENT_REQUIRED'
export const ACTION_HUB_UPDATE_IS_COMMENT_RECEIVED = 'ACTION_HUB_UPDATE_IS_COMMENT_RECEIVED'
export const ACTION_HUB_UPDATE_SIGN_OFF_DETAILS = 'ACTION_HUB_UPDATE_SIGN_OFF_DETAILS'

export const ACTION_HUB_SUBMITTING_TASK_COMMENT_FAILED = 'ACTION_HUB_SUBMITTING_TASK_COMMENT_FAILED'

export const ACTION_HUB_SUBMITTED_TASK_COMMENT_STATUS = 'ACTION_HUB_SUBMITTED_TASK_COMMENT_STATUS'

export const ACTION_HUB_FETCHED_TASK_COMMENTS = 'ACTION_HUB_FETCHED_TASK_COMMENTS'

export const RESET_LABELS = 'RESET_LABELS'

export const ACTION_HUB_FETCHING_COMMENT_AUTHORS = 'ACTION_HUB_FETCHING_COMMENT_AUTHORS'
export const ACTION_HUB_FETCHED_COMMENT_AUTHOR = 'ACTION_HUB_FETCHED_COMMENT_AUTHOR'
