export const isNullOrUndefined = (value) => {
  return value === null || typeof value === 'undefined'
}

export const isNumeric = (value) => {
  return /^[0-9]+(\.[0-9]+)?$/.test(value)
}

export const formatDate = (isoDate) => {
  const date = new Date(isoDate)
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(date)
}

export const formatDateWithTime = (isoDate) => {
  const date = new Date(isoDate);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // This ensures a 12-hour clock with AM/PM
  }).format(date)
}
